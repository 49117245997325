import React from 'react';
import PropTypes from 'prop-types';

function Video({ video }) {
  return (
    <video
      className="absolute inset-0 w-full h-full object-cover"
      autoPlay
      loop
      muted
    >
      <source src={video} />
    </video>
  );
}

Video.propTypes = {
  video: PropTypes.string.isRequired,
};

export default Video;
