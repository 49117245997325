import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formattedDate, onClickBlogItem } from '../../utils/common';
import NoImageAvailable from '../../assets/images/no-image-available.png';

function BlogThumbItemOne({
  slug, date, title, image,
}) {
  return (
    <Link to={`/blogs/${slug}`} onClick={onClickBlogItem}>
      <div className="text-white duration-500 group max-w-[720px] max-h-[480px]">
        <div className="relative overflow-hidden">
          <img
            src={
              image
                ? `${process.env.REACT_APP_BASE_URL}${image}`
                : NoImageAvailable
            }
            alt="Blog"
            className="
              relative
              inset-0
              md:w-[720px]
              md:h-[480px]
              object-cover
              group-hover:scale-110
              duration-500"
          />
          <div
            className="
              absolute
              bottom-0
              p-5
              font-semibold
              bg-black
              backdrop-blur-md
              bg-opacity-75
              w-full
              max-h-[140px]
              group-hover:text-[#2F80ED]
              duration-500"
          >
            <p className="text-xs md:text-sm lg:text-base">
              {formattedDate(date)}
            </p>
            <h2 className="text-xs md:text-sm lg:text-base xl:text-lg py-2">
              {title}
            </h2>
          </div>
        </div>
      </div>
    </Link>
  );
}

BlogThumbItemOne.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

BlogThumbItemOne.defaultProps = {
  image: null,
};

export default BlogThumbItemOne;
