import getBlogDetail from '../../data/remote/collection/getBlogDetail';
import {
  clearBlogDetailActionCreator,
  receiveBlogDetailActionCreator,
  setIsErrorBlogDetailActionCreator,
  setIsLoadingBlogDetailActionCreator,
} from './action';

function asyncReceiveBlogDetail(slug) {
  return async (dispatch) => {
    dispatch(setIsLoadingBlogDetailActionCreator(true));
    dispatch(clearBlogDetailActionCreator());
    try {
      const { data } = await getBlogDetail(slug);
      dispatch(receiveBlogDetailActionCreator(data[0]));
      dispatch(setIsErrorBlogDetailActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorBlogDetailActionCreator(true));
    } finally {
      dispatch(setIsLoadingBlogDetailActionCreator(false));
    }
  };
}

export default asyncReceiveBlogDetail;
