import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

function BlogThumbInfoTwo({ date, title, body }) {
  return (
    <div className="flex flex-col gap-2 max-w-[500px] w-full">
      <p className="text-[#8C8C8C] text-xs md:text-base">{date}</p>
      <div className="flex flex-col gap-2">
        <h2
          className="
            text-lg
            md:text-xl
            lg:text-2xl
            xl:text-3xl
            py-2
            font-semibold
            line-clamp-2
            text-ellipsis"
        >
          {title}
        </h2>
        <ReactMarkdown
          className="
            text-[#8C8C8C] text-xs md:text-base lg:text-lg text-ellipsis line-clamp-3"
        >
          {body}
        </ReactMarkdown>
      </div>
    </div>
  );
}

BlogThumbInfoTwo.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default BlogThumbInfoTwo;
