import React from 'react';
import PropTypes from 'prop-types';
import AboutUsDesc from '../molecules/AboutUsDesc';
import Quote from '../molecules/Quote';
import AboutUsServiceList from '../molecules/AboutUsServiceList';
import AboutHeadingSkeleton from '../skeletons/AboutHeadingSkeleton';
import QuoteImageSkeleton from '../skeletons/QuoteImageSkeleton';
import ServicesImageSkeleton from '../skeletons/ServicesImageSkeleton';
import AboutUsServicesListSkeleton from '../skeletons/AboutUsServicesListSkeleton';
import TextHeadingSection from '../atoms/TextHeadingSection';
import ImageQuote from '../atoms/ImageQuote';
import Background from '../../assets/images/background.png';

function MainAboutUs({
  aboutHeading,
  aboutBody,
  firstQuoteImage,
  firstQuoteContent,
  firstQuoteSpeaker,
  firstQuoteSpeakerTitle,
  servicesImage,
  isLoadingAbout,
}) {
  return (
    <main>
      <section className="relative bg-[#F4F4F4]">
        <img
          src={Background}
          alt="background images"
          className="absolute w-full h-full left-0 bottom-0 object-cover"
        />
        <div
          className="
            container
            m-auto
            flex
            flex-col
            gap-10
            items-center
            py-[24px]
            md:py-[96px]
            px-5
            text-center"
        >
          {isLoadingAbout ? (
            <AboutHeadingSkeleton />
          ) : (
            <TextHeadingSection text={aboutHeading} />
          )}
          <div className="w-full m-auto">
            <AboutUsDesc aboutBody={aboutBody} isLoading={isLoadingAbout} />
          </div>
        </div>
      </section>
      <section
        className="
          container
          m-auto
          flex
          flex-col
          xl:flex-row
          gap-8
          md:gap-16
          justify-between
          items-center
          py-[24px]
          md:py-[120px]
          px-5"
      >
        {isLoadingAbout ? (
          <QuoteImageSkeleton />
        ) : (
          <ImageQuote image={firstQuoteImage} />
        )}
        <Quote
          quoteContent={firstQuoteContent}
          quoteSpeaker={firstQuoteSpeaker}
          quoteSpeakerTitle={firstQuoteSpeakerTitle}
          isLoading={isLoadingAbout}
        />
      </section>
      {/* <section
        className="
          container
          m-auto
          flex
          flex-col-reverse
          xl:flex-row
          gap-8
          md:gap-16
          justify-between
          items-center
          py-[24px]
          md:py-[120px]
          px-5"
      >
        <Quote
          quoteContent={secondQuoteContent}
          quoteSpeaker={secondQuoteSpeaker}
          quoteSpeakerTitle={secondQuoteSpeakerTitle}
          isLoading={isLoadingAbout}
        />
        {isLoadingAbout ? (
          <QuoteImageSkeleton />
        ) : (
          <ImageQuote image={secondQuoteImage} />
        )}
      </section> */}
      <section className="bg-[#F4F4F4]">
        <div className="container m-auto flex flex-col-reverse xl:flex-row px-5 gap-32">
          <div className="flex flex-col gap-10 py-20">
            <TextHeadingSection text="What we do?" />
            {isLoadingAbout ? (
              <AboutUsServicesListSkeleton />
            ) : (
              <AboutUsServiceList />
            )}
          </div>
          <div className="m-auto xl:m-0 max-w-[602px] max-h-[568px] hidden lg:block">
            {isLoadingAbout ? (
              <ServicesImageSkeleton />
            ) : (
              <img
                src={servicesImage}
                alt="Service"
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      </section>
      {/* <section
        className="
          container
          m-auto
          flex
          flex-col
          gap-20
          py-12
          md:py-32
          px-5
          items-center"
      >
        <TextHeadingSection text="Client & Partner" />
        <ClientPartnerList
          clients={clients}
          isLoading={isLoadingClients}
          isError={isErrorClients}
        />
      </section> */}
    </main>
  );
}

MainAboutUs.propTypes = {
  aboutHeading: PropTypes.string.isRequired,
  aboutBody: PropTypes.string.isRequired,
  firstQuoteImage: PropTypes.string.isRequired,
  firstQuoteContent: PropTypes.string.isRequired,
  firstQuoteSpeaker: PropTypes.string.isRequired,
  firstQuoteSpeakerTitle: PropTypes.string.isRequired,
  isLoadingAbout: PropTypes.bool,
  servicesImage: PropTypes.string.isRequired,
};

MainAboutUs.defaultProps = {
  isLoadingAbout: null,
};

export default MainAboutUs;
