import React from 'react';
import PropTypes from 'prop-types';

function MessageBarOne({ message }) {
  return (
    <p className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold text-center">
      {message}
    </p>
  );
}

MessageBarOne.propTypes = {
  message: PropTypes.string.isRequired,
};

export default MessageBarOne;
