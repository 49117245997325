import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import asyncPostSubscriber from './stores/subscribers/thunk';
import HomePage from './components/pages/HomePage';
import AboutUsPage from './components/pages/AboutUsPage';
import BlogPage from './components/pages/BlogPage';
import DetailBlogPage from './components/pages/DetailBlogPage';
import ContactUsPage from './components/pages/ContactUsPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import NotFoundPage from './components/pages/NotFoundPage';

function App() {
  const dispatch = useDispatch();
  const onSubscribe = (email) => dispatch(asyncPostSubscriber(email));
  const { isLoading, isSuccess, isError } = useSelector(
    (states) => states.subscribers,
  );

  return (
    <Routes>
      <Route
        path="/"
        element={(
          <HomePage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isErrorSubscribers={isError}
          />
        )}
      />
      <Route
        path="/home"
        element={(
          <HomePage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isErrorSubscribers={isError}
          />
        )}
      />
      <Route
        path="/about-us"
        element={(
          <AboutUsPage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isErrorSubscribers={isError}
          />
        )}
      />
      <Route path="/blogs" element={<BlogPage />} />
      <Route path="/blogs/:slug" element={<DetailBlogPage />} />
      <Route
        path="/contact-us"
        element={(
          <ContactUsPage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isError={isError}
          />
        )}
      />
      <Route
        path="/privacy-policy"
        element={(
          <PrivacyPolicyPage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isErrorSubscribers={isError}
          />
        )}
      />
      <Route
        path="/*"
        element={(
          <NotFoundPage
            onSubscribe={onSubscribe}
            isLoadingSubscribers={isLoading}
            isSuccessSubscribers={isSuccess}
            isErrorSubscribers={isError}
          />
        )}
      />
    </Routes>
  );
}

export default App;
