import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import HeaderNotFound from '../organisms/HeaderNotFound';
import MainNotFound from '../organisms/MainNotFound';
import Footer from '../organisms/Footer';

function NotFoundPage({
  onSubscribe,
  isLoadingSubscribers,
  isSuccessSubscribers,
  isErrorSubscribers,
}) {
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <HeaderNotFound />
      <MainNotFound />
      <Footer
        onSubscribe={onSubscribe}
        isLoading={isLoadingSubscribers}
        isSuccess={isSuccessSubscribers}
        isError={isErrorSubscribers}
      />
    </motion.div>
  );
}

NotFoundPage.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  isLoadingSubscribers: PropTypes.bool,
  isSuccessSubscribers: PropTypes.bool,
  isErrorSubscribers: PropTypes.bool,
};

NotFoundPage.defaultProps = {
  isLoadingSubscribers: null,
  isSuccessSubscribers: null,
  isErrorSubscribers: null,
};

export default NotFoundPage;
