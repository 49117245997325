import { configureStore } from '@reduxjs/toolkit';
import homeReducer from './home/reducer';
import aboutReducer from './about/reducer';
import contactReducer from './contact/reducer';
import privacyPolicyReducer from './privacyPolicy/reducer';
import servicesHomeReducer from './servicesHome/reducer';
import headlineBlogsReducer from './headlineBlogs/reducer';
import servicesAboutReducer from './servicesAbout/reducer';
import clientsReducer from './clients/reducer';
import blogsReducer from './blogs/reducer';
import topicsReducer from './topics/reducer';
import blogDetailReducer from './blogDetail/reducer';
import subscribersReducer from './subscribers/reducer';
import emailsReducer from './emails/reducer';

const store = configureStore({
  reducer: {
    home: homeReducer,
    about: aboutReducer,
    contact: contactReducer,
    privacyPolicy: privacyPolicyReducer,
    servicesHome: servicesHomeReducer,
    headlineBlogs: headlineBlogsReducer,
    servicesAbout: servicesAboutReducer,
    clients: clientsReducer,
    blogs: blogsReducer,
    topics: topicsReducer,
    blogDetail: blogDetailReducer,
    subscribers: subscribersReducer,
    emails: emailsReducer,
  },
});

export default store;
