import getHeadlineBlogs from '../../data/remote/collection/getHeadlineBlogs';
import {
  receiveHeadlineBlogsActionCreator,
  setIsErrorHeadlineBlogsActionCreator,
  setIsLoadingHeadlineBlogsActionCreator,
} from './action';

function asyncReceiveHeadlineBlogs() {
  return async (dispatch) => {
    dispatch(setIsLoadingHeadlineBlogsActionCreator(true));
    try {
      const { data } = await getHeadlineBlogs();
      dispatch(receiveHeadlineBlogsActionCreator(data));
      dispatch(setIsErrorHeadlineBlogsActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorHeadlineBlogsActionCreator(true));
    } finally {
      dispatch(setIsLoadingHeadlineBlogsActionCreator(false));
    }
  };
}

export default asyncReceiveHeadlineBlogs;
