import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ContactUsButton from './ContactUsButton';
import TextLinkThree from '../atoms/TextLinkThree';
import TextLinkFour from '../atoms/TextLinkFour';
import TextLinkFive from '../atoms/TextLinkFive';

function Navigation({ pathname }) {
  if (pathname === '/' || pathname === '/home') {
    return (
      <ul
        className="
          hidden
          md:flex
          gap-8
          font-semibold
          items-center
          text-white
          text-xs
          md:text-sm
          lg:text-base
          xl:text-lg"
      >
        <TextLinkThree text="About Us" destination="/about-us" />
        <TextLinkThree text="Highlights" destination="/blogs" />
        <li>
          <Link to="/contact-us">
            <ContactUsButton dark={false} />
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <ul
      className="
        hidden
        md:flex
        gap-8
        font-semibold
        items-center
        text-black
        text-xs
        md:text-sm
        lg:text-base
        xl:text-lg"
    >
      <li>
        {pathname === '/about-us' ? (
          <TextLinkFour text="About Us" destination="/about-us" />
        ) : (
          <TextLinkFive text="About Us" destination="/about-us" />
        )}
      </li>
      <li>
        {pathname === '/blogs' ? (
          <TextLinkFour text="Highlights" destination="/blogs" />
        ) : (
          <TextLinkFive text="Highlights" destination="/blogs" />
        )}
      </li>
      <li>
        <Link to="/contact-us">
          <ContactUsButton />
        </Link>
      </li>
    </ul>
  );
}

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Navigation;
