import React from 'react';
import PropTypes from 'prop-types';
import Image from '../atoms/Image';

function ImageBackground({ image }) {
  return (
    <>
      <Image image={image} />
      <div className="absolute inset-0 bg-black opacity-75" />
    </>
  );
}

ImageBackground.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ImageBackground;
