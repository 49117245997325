import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbListOne from '../molecules/BlogThumbListOne';
import TextHeadingSection from '../atoms/TextHeadingSection';
import blogShape from '../../utils/propHelper/blogShape';

function MainBlogPageFirstSection({ headlineBlogs, isLoading, isError }) {
  return (
    <section className="container m-auto flex flex-col gap-5 items-center">
      <div className="py-8">
        <TextHeadingSection text="Maton Highlights" />
      </div>
      <BlogThumbListOne
        headlineBlogs={headlineBlogs}
        isLoading={isLoading}
        isError={isError}
      />
    </section>
  );
}

MainBlogPageFirstSection.propTypes = {
  headlineBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

MainBlogPageFirstSection.defaultProps = {
  headlineBlogs: null,
  isLoading: null,
  isError: null,
};

export default MainBlogPageFirstSection;
