async function getAllBlogs(page) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/blogs?sort=publishedAt:desc&populate=*&pagination[pageSize]=5&pagination[page]=${page}`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export default getAllBlogs;
