import React from 'react';
import PropTypes from 'prop-types';

function ImageQuote({ image }) {
  return <img src={image} alt="Quote" className="w-full max-w-[560px]" />;
}

ImageQuote.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ImageQuote;
