import React from 'react';

function QuoteContentSkeleton() {
  return (
    <div
      role="status"
      className="w-full flex flex-col justify-center animate-pulse"
    >
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <div className="h-2.5 xl:h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4" />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default QuoteContentSkeleton;
