import React from 'react';
import PropTypes from 'prop-types';
import HeroItem from '../molecules/HeroItem';
import Navbar from './Navbar';
import HeroContent from '../molecules/HeroContent';

function HeaderHome({
  heroMedia, heroHeading, heroBody, isLoading,
}) {
  return (
    <header className="relative h-[50vh] md:h-screen">
      <HeroItem video={heroMedia} isLoading={isLoading} />
      <Navbar dark={false} />
      <HeroContent
        heroHeading={heroHeading}
        heroBody={heroBody}
        isLoading={isLoading}
      />
    </header>
  );
}

HeaderHome.propTypes = {
  heroMedia: PropTypes.string.isRequired,
  heroHeading: PropTypes.string.isRequired,
  heroBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

HeaderHome.defaultProps = {
  isLoading: null,
};

export default HeaderHome;
