const ActionType = {
  RECEIVE_HEADLINE_BLOGS: 'RECEIVE_HEADLINE_BLOGS',
  SET_IS_LOADING_HEADLINE_BLOGS: 'SET_IS_LOADING_HEADLINE_BLOGS',
  SET_IS_ERROR_HEADLINE_BLOGS: 'SET_IS_ERROR_HEADLINE_BLOGS',
};

function receiveHeadlineBlogsActionCreator(headlineBlogs) {
  return {
    type: ActionType.RECEIVE_HEADLINE_BLOGS,
    payload: {
      headlineBlogs,
    },
  };
}

function setIsLoadingHeadlineBlogsActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_HEADLINE_BLOGS,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorHeadlineBlogsActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_HEADLINE_BLOGS,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveHeadlineBlogsActionCreator,
  setIsLoadingHeadlineBlogsActionCreator,
  setIsErrorHeadlineBlogsActionCreator,
};
