const ActionType = {
  RECEIVE_ALL_TOPICS: 'RECEIVE_ALL_TOPICS',
  SET_IS_LOADING_TOPICS: 'SET_IS_LOADING_TOPICS',
  SET_IS_ERROR_TOPICS: 'SET_IS_ERROR_TOPICS',
};

function receiveAllTopicsActionCreator(topics) {
  return {
    type: ActionType.RECEIVE_ALL_TOPICS,
    payload: {
      topics,
    },
  };
}

function setIsLoadingTopicsActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_TOPICS,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorTopicsActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_TOPICS,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveAllTopicsActionCreator,
  setIsLoadingTopicsActionCreator,
  setIsErrorTopicsActionCreator,
};
