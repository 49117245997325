import initialState from './initialState';
import { ActionType } from './action';

function headlineBlogsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_HEADLINE_BLOGS:
      return {
        ...state,
        headlineBlogs: action.payload.headlineBlogs,
      };
    case ActionType.SET_IS_ERROR_HEADLINE_BLOGS:
      return {
        ...state,
        isError: action.payload.isError,
      };
    case ActionType.SET_IS_LOADING_HEADLINE_BLOGS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
}

export default headlineBlogsReducer;
