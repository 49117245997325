import React from 'react';
import PropTypes from 'prop-types';
import HomeServiceList from '../molecules/HomeServiceList';
import TextHeadingSection from '../atoms/TextHeadingSection';
import homeServiceShape from '../../utils/propHelper/homeServiceShape';

function MainHomeSecondSection({
  services,
  isLoadingServices,
  isErrorServices,
}) {
  return (
    <section
      className="
        container
        m-auto
        flex
        flex-col
        gap-5
        md:gap-20
        items-center
        py-[40px]
        md:py-[80px]
        px-5
        font-lato"
    >
      <TextHeadingSection text="Our Services" />
      <HomeServiceList
        services={services}
        isLoadingServices={isLoadingServices}
        isErrorServices={isErrorServices}
      />
    </section>
  );
}

MainHomeSecondSection.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape(homeServiceShape)),
  isLoadingServices: PropTypes.bool,
  isErrorServices: PropTypes.bool,
};

MainHomeSecondSection.defaultProps = {
  services: null,
  isLoadingServices: null,
  isErrorServices: null,
};

export default MainHomeSecondSection;
