import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import ContactUsButton from './ContactUsButton';
import TextLinkTwo from '../atoms/TextLinkTwo';
import TextLinkSix from '../atoms/TextLinkSix';
import TextLinkSeven from '../atoms/TextLinkSeven';

function NavigationDrawer({ pathname }) {
  if (pathname === '/' || pathname === '/home') {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1, ease: 'easeOut' }}
        exit={{ opacity: 0 }}
      >
        <ul
          className="
            flex
            flex-col
            md:hidden
            gap-5
            p-5
            font-semibold
            items-center
            text-xs
            md:text-sm
            lg:text-base
            xl:text-lg
            bg-white
            absolute
            w-full
            mt-2"
        >
          <TextLinkTwo text="About Us" destination="/about-us" />
          <TextLinkTwo text="Highlights" destination="/blogs" />
          <div className="w-full">
            <li>
              <Link to="/contact-us">
                <ContactUsButton />
              </Link>
            </li>
          </div>
        </ul>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <ul
        className="
          flex
          flex-col
          md:hidden
          gap-5
          p-5
          font-semibold
          items-center
          text-black
          text-xs
          md:text-sm
          lg:text-base
          xl:text-lg
          bg-white
          absolute
          w-full
          mt-2
          border-b-2"
      >
        {pathname === '/about-us' ? (
          <TextLinkSix text="About Us" destination="/about-us" />
        ) : (
          <TextLinkSeven text="About Us" destination="/about-us" />
        )}
        {pathname === '/blogs' ? (
          <TextLinkSix text="Highlights" destination="/blogs" />
        ) : (
          <TextLinkSeven text="Highlights" destination="/blogs" />
        )}
        <div className="w-full">
          <li>
            <Link to="/contact-us">
              <ContactUsButton />
            </Link>
          </li>
        </div>
      </ul>
    </motion.div>
  );
}

NavigationDrawer.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default NavigationDrawer;
