import React from 'react';
import TextLinkOne from '../atoms/TextLinkOne';

function SideNavigation() {
  return (
    <nav>
      <ul className="flex gap-5 text-sm md:text-base">
        <TextLinkOne text="Contact Us" destination="/contact-us" />
        <TextLinkOne text="About Us" destination="/about-us" />
        <TextLinkOne text="Privacy Policy" destination="/privacy-policy" />
      </ul>
    </nav>
  );
}

export default SideNavigation;
