import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbItemFive from './BlogThumbItemFive';
import BlogThumbListTwoSkeleton from '../skeletons/BlogThumbListTwoSkeleton';
import blogShape from '../../utils/propHelper/blogShape';

function BlogThumbListTwo({
  blogs,
  params,
  isNull,
  isLoading,
  isError,
  onClickLoadMore,
}) {
  const dummy = () => {
    if (blogs === null) {
      return (
        <div className="text-center">
          <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
            No blogs available
          </h3>
        </div>
      );
    }

    if (!isLoading) {
      if (blogs?.length === 0) {
        return (
          <div className="text-center">
            <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
              No blogs available
            </h3>
          </div>
        );
      }
    }

    if (isLoading) return <BlogThumbListTwoSkeleton />;

    return '';
  };

  if (isError) {
    return (
      <div className="text-center">
        <h3 className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl font-semibold">
          Failed to fetch blogs
        </h3>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      {blogs?.length
        ? blogs.map((blog) => (
          <BlogThumbItemFive
            key={blog.id}
            slug={blog.attributes.slug}
            date={blog.attributes.publishedAt}
            title={blog.attributes.title}
            body={blog.attributes.body}
            image={blog.attributes.image.data?.attributes.url}
            params={params}
          />
        ))
        : ''}
      {dummy()}
      {isNull || blogs?.length === 0 ? (
        ''
      ) : (
        <button
          type="button"
          onClick={() => onClickLoadMore()}
          className="border p-1"
        >
          Load more
        </button>
      )}
    </div>
  );
}

BlogThumbListTwo.propTypes = {
  blogs: PropTypes.arrayOf(PropTypes.shape(blogShape)).isRequired,
  params: PropTypes.string,
  isNull: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
};

BlogThumbListTwo.defaultProps = {
  params: null,
  isNull: null,
  isLoading: null,
  isError: null,
};

export default BlogThumbListTwo;
