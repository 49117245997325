import React from 'react';
import PropTypes from 'prop-types';
import HomeServiceItem from './HomeServiceItem';
import MessageBarOne from './MessageBarOne';
import HomeServicesListSkeleton from '../skeletons/HomeServicesListSkeleton';
import homeServiceShape from '../../utils/propHelper/homeServiceShape';

function HomeServiceList({ services, isLoadingServices, isErrorServices }) {
  if (isLoadingServices) {
    return (
      <div className="text-center py-4 w-full">
        <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
          {isLoadingServices ? (
            <HomeServicesListSkeleton />
          ) : (
            'No services available'
          )}
        </h3>
      </div>
    );
  }

  if (isErrorServices) {
    return (
      <div className="text-center py-4">
        <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
          Failed to fetch services
        </h3>
      </div>
    );
  }

  if (services === null) {
    return <MessageBarOne message="No services available" />;
  }

  if (services.length === 0) {
    return <MessageBarOne message="No services available" />;
  }

  return (
    <div className="flex flex-col md:flex-row gap-5">
      {services?.map((service) => (
        <HomeServiceItem
          key={service.id}
          name={service.attributes.name}
          description={service.attributes.description}
          image={service.attributes.image.data.attributes.url}
        />
      ))}
    </div>
  );
}

HomeServiceList.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape(homeServiceShape)),
  isLoadingServices: PropTypes.bool,
  isErrorServices: PropTypes.bool,
};

HomeServiceList.defaultProps = {
  services: null,
  isLoadingServices: null,
  isErrorServices: null,
};

export default HomeServiceList;
