import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbItemOne from './BlogThumbItemOne';
import BlogThumbItemTwo from './BlogThumbItemTwo';
import BlogThumbItemThree from './BlogThumbItemThree';
import BlogThumbItemFour from './BlogThumbItemFour';
import BlogThumbListOneSkeleton from '../skeletons/BlogThumbListOneSkeleton';
import blogShape from '../../utils/propHelper/blogShape';

function BlogThumbListOne({ headlineBlogs, isLoading, isError }) {
  if (headlineBlogs !== null) {
    switch (headlineBlogs.length) {
      case 1:
        return (
          <div className="flex item-center flex-wrap justify-center">
            <BlogThumbItemOne
              slug={headlineBlogs[0]?.attributes.slug}
              date={headlineBlogs[0]?.attributes.publishedAt}
              title={headlineBlogs[0]?.attributes.title}
              image={headlineBlogs[0]?.attributes.image.data?.attributes.url}
            />
          </div>
        );
      case 2:
        return (
          <div className="flex item-center flex-wrap justify-center">
            <BlogThumbItemOne
              slug={headlineBlogs[0]?.attributes.slug}
              date={headlineBlogs[0]?.attributes.publishedAt}
              title={headlineBlogs[0]?.attributes.title}
              image={headlineBlogs[0]?.attributes.image.data?.attributes.url}
            />
            <BlogThumbItemTwo
              slug={headlineBlogs[1]?.attributes.slug}
              date={headlineBlogs[1]?.attributes.publishedAt}
              title={headlineBlogs[1]?.attributes.title}
              image={headlineBlogs[1]?.attributes.image.data?.attributes.url}
            />
          </div>
        );
      case 3:
        return (
          <div className="flex flex-col md:flex-row item-center justify-center">
            <BlogThumbItemOne
              slug={headlineBlogs[0]?.attributes.slug}
              date={headlineBlogs[0]?.attributes.publishedAt}
              title={headlineBlogs[0]?.attributes.title}
              image={headlineBlogs[0]?.attributes.image.data?.attributes.url}
            />
            <div className="flex flex-col flex-wrap">
              <BlogThumbItemTwo
                slug={headlineBlogs[1]?.attributes.slug}
                date={headlineBlogs[1]?.attributes.publishedAt}
                title={headlineBlogs[1]?.attributes.title}
                image={headlineBlogs[1]?.attributes.image.data?.attributes.url}
              />
              <BlogThumbItemThree
                slug={headlineBlogs[2]?.attributes.slug}
                date={headlineBlogs[2]?.attributes.publishedAt}
                title={headlineBlogs[2]?.attributes.title}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex flex-col flex-wrap">
            <div className="flex item-center flex-wrap justify-center">
              <BlogThumbItemOne
                slug={headlineBlogs[0]?.attributes.slug}
                date={headlineBlogs[0]?.attributes.publishedAt}
                title={headlineBlogs[0]?.attributes.title}
                image={headlineBlogs[0]?.attributes.image.data?.attributes.url}
              />
              <div className="flex flex-col flex-wrap">
                <BlogThumbItemTwo
                  slug={headlineBlogs[1]?.attributes.slug}
                  date={headlineBlogs[1]?.attributes.publishedAt}
                  title={headlineBlogs[1]?.attributes.title}
                  image={
                    headlineBlogs[1]?.attributes.image.data?.attributes.url
                  }
                />
                <BlogThumbItemThree
                  slug={headlineBlogs[2]?.attributes.slug}
                  date={headlineBlogs[2]?.attributes.publishedAt}
                  title={headlineBlogs[2]?.attributes.title}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
              <BlogThumbItemFour
                slug={headlineBlogs[3]?.attributes.slug}
                date={headlineBlogs[3]?.attributes.publishedAt}
                title={headlineBlogs[3]?.attributes.title}
                image={headlineBlogs[3]?.attributes.image.data?.attributes.url}
              />
            </div>
          </div>
        );
      case 5:
        return (
          <div className="flex flex-col flex-wrap">
            <div className="flex item-center flex-wrap justify-center">
              <BlogThumbItemOne
                slug={headlineBlogs[0]?.attributes.slug}
                date={headlineBlogs[0]?.attributes.publishedAt}
                title={headlineBlogs[0]?.attributes.title}
                image={headlineBlogs[0]?.attributes.image.data?.attributes.url}
              />
              <div className="flex flex-col flex-wrap">
                <BlogThumbItemTwo
                  slug={headlineBlogs[1]?.attributes.slug}
                  date={headlineBlogs[1]?.attributes.publishedAt}
                  title={headlineBlogs[1]?.attributes.title}
                  image={
                    headlineBlogs[1]?.attributes.image.data?.attributes.url
                  }
                />
                <BlogThumbItemThree
                  slug={headlineBlogs[2]?.attributes.slug}
                  date={headlineBlogs[2]?.attributes.publishedAt}
                  title={headlineBlogs[2]?.attributes.title}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
              <BlogThumbItemFour
                slug={headlineBlogs[3]?.attributes.slug}
                date={headlineBlogs[3]?.attributes.publishedAt}
                title={headlineBlogs[3]?.attributes.title}
                image={headlineBlogs[3]?.attributes.image.data?.attributes.url}
              />
              <BlogThumbItemFour
                slug={headlineBlogs[4]?.attributes.slug}
                date={headlineBlogs[4]?.attributes.publishedAt}
                title={headlineBlogs[4]?.attributes.title}
                image={headlineBlogs[4]?.attributes.image.data?.attributes.url}
              />
            </div>
          </div>
        );
      default:
        if (isError) {
          return (
            <div className="text-center mb-32">
              <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
                Failed to fetch blogs
              </h3>
            </div>
          );
        }

        return (
          <div className="text-center w-full mb-24">
            <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
              {isLoading ? <BlogThumbListOneSkeleton /> : 'No blogs available'}
            </h3>
          </div>
        );
    }
  }

  return (
    <div className="text-center w-full">
      <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-semibold">
        {isLoading ? <BlogThumbListOneSkeleton /> : 'No blogs available'}
      </h3>
    </div>
  );
}

BlogThumbListOne.propTypes = {
  headlineBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)).isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

BlogThumbListOne.defaultProps = {
  isLoading: null,
  isError: null,
};

export default BlogThumbListOne;
