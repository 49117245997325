import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
import Text from '../atoms/Text';
import aboutServiceShape from '../../utils/propHelper/aboutServiceShape';

function AboutUsServiceItem({ service }) {
  return (
    <div className="flex gap-5 items-center">
      <div>
        <AiFillCheckCircle size={30} />
      </div>
      <Text text={service.name} />
    </div>
  );
}

AboutUsServiceItem.propTypes = {
  service: PropTypes.shape(aboutServiceShape).isRequired,
};

export default AboutUsServiceItem;
