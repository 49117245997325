import React from 'react';
import TextLinkOne from '../atoms/TextLinkOne';

function BottomNavigation() {
  return (
    <nav>
      <ul
        className="
          flex
          gap-5
          font-semibold
          text-white
          text-xs
          md:text-sm
          lg:text-base
          xl:text-lg"
      >
        <TextLinkOne text="Contact Us" destination="/contact-us" />
        <TextLinkOne text="Privacy Policy" destination="/privacy-policy" />
      </ul>
    </nav>
  );
}

export default BottomNavigation;
