import React from 'react';
import TopicItemSkeleton from './TopicItemSkeleton';

function TopicsListOneSkeleton() {
  return (
    <div className="flex gap-5">
      <TopicItemSkeleton />
      <TopicItemSkeleton />
    </div>
  );
}

export default TopicsListOneSkeleton;
