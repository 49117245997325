const ActionType = {
  RECEIVE_ALL_CLIENTS: 'RECEIVE_ALL_CLIENTS',
  SET_IS_ERROR_CLIENTS: 'SET_IS_ERROR_CLIENTS',
  SET_IS_LOADING_CLIENTS: 'SET_IS_LOADING_CLIENTS',
};

function receiveAllClientsActionCreator(clients) {
  return {
    type: ActionType.RECEIVE_ALL_CLIENTS,
    payload: {
      clients,
    },
  };
}

function setIsLoadingClientsActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_CLIENTS,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorClientsActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_CLIENTS,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveAllClientsActionCreator,
  setIsLoadingClientsActionCreator,
  setIsErrorClientsActionCreator,
};
