import React from 'react';
import PropTypes from 'prop-types';

function ContactUsButton({ dark = true }) {
  return (
    <button
      type="button"
      className={`${
        dark ? 'bg-black text-white' : 'bg-white text-black'
      } px-8 py-2 rounded-full hover:shadow-lg w-full`}
    >
      Contact Us
    </button>
  );
}

ContactUsButton.propTypes = {
  dark: PropTypes.bool,
};

ContactUsButton.defaultProps = {
  dark: true,
};

export default ContactUsButton;
