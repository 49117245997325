import PropTypes from 'prop-types';
import imageDataShape from './imageDataShape';

const blogAttributeShape = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.shape(imageDataShape).isRequired,
  publishedAt: PropTypes.string.isRequired,
};

export default blogAttributeShape;
