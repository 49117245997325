import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { formattedDate, onClickBlogItem } from '../../utils/common';

function BlogThumbItemThree({ slug, date, title }) {
  return (
    <div className="bg-[#101828] max-w-[720px] max-h-[240px]">
      <Link
        to={`/blogs/${slug}`}
        className="
          text-white
          px-5
          py-11
          flex
          flex-col
          gap-2
          justify-center
          hover:text-[#2F80ED]
          group"
        onClick={onClickBlogItem}
      >
        <div className="font-semibold flex flex-col gap-2">
          <p
            className="
              text-xs
              group-hover:text-[.85rem]
              md:text-sm
              md:group-hover:text-[.975rem]
              lg:text-base
              lg:group-hover:text-[1.1rem]
              duration-500"
          >
            {formattedDate(date)}
          </p>
          <h2
            className="
              text-sm
              group-hover:text-[1.1rem]
              md:text-base
              md:group-hover:text-[1.225rem]
              lg:text-lg
              lg:group-hover:text-[1.35rem]
              xl:text-xl
              xl:group-hover:text-[1.6rem]
              duration-500
              py-2"
          >
            {title}
          </h2>
        </div>
        <div className="flex just items-center gap-2 mt-5 text-lg">
          <p className="text-xs md:text-sm lg:text-base">Read more </p>
          <AiOutlineArrowRight />
        </div>
      </Link>
    </div>
  );
}

BlogThumbItemThree.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogThumbItemThree;
