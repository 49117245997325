import getHome from '../../data/remote/single/getHome';
import {
  receiveHomeActionCreator,
  setIsErrorHomeActionCreator,
  setIsLoadingHomeActionCreator,
} from './action';

function asyncReceiveHome() {
  return async (dispatch) => {
    dispatch(setIsLoadingHomeActionCreator(true));
    try {
      const { data } = await getHome();
      dispatch(receiveHomeActionCreator(data));
      dispatch(setIsErrorHomeActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorHomeActionCreator(true));
    } finally {
      dispatch(setIsLoadingHomeActionCreator(false));
    }
  };
}

export default asyncReceiveHome;
