import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonIcon from '../atoms/ButtonIcon';

function BackButton() {
  const navigate = useNavigate();

  return <ButtonIcon label="Back to Maton Blog" action={() => navigate(-1)} />;
}

export default BackButton;
