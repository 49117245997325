import initialState from './initialState';
import { ActionType } from './action';

function contactReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_CONTACT:
      return {
        ...state,
        contact: action.payload.contact,
      };
    case ActionType.SET_IS_LOADING_CONTACT:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_CONTACT:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default contactReducer;
