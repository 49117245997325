import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkThree({ text, destination }) {
  return (
    <li>
      <Link
        to={destination}
        className="hover:underline hover:decoration-4 hover:underline-offset-8"
      >
        {text}
      </Link>
    </li>
  );
}

TextLinkThree.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkThree;
