import getAllClients from '../../data/remote/collection/getAllClients';
import {
  receiveAllClientsActionCreator,
  setIsErrorClientsActionCreator,
  setIsLoadingClientsActionCreator,
} from './action';

function asyncReceiveAllClients() {
  return async (dispatch) => {
    dispatch(setIsLoadingClientsActionCreator(true));
    try {
      const { data } = await getAllClients();
      dispatch(receiveAllClientsActionCreator(data));
      dispatch(setIsErrorClientsActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorClientsActionCreator(true));
    } finally {
      dispatch(setIsLoadingClientsActionCreator(false));
    }
  };
}

export default asyncReceiveAllClients;
