import { ActionType } from './action';
import initialState from './initialState';

function servicesHomeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_SERVICES_HOME:
      return {
        ...state,
        servicesHome: action.payload.servicesHome,
      };
    case ActionType.SET_IS_LOADING_SERVICES_HOME:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_SERVICES_HOME:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default servicesHomeReducer;
