async function getAllTopics() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/topics`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export default getAllTopics;
