import React from 'react';

function HeroImageSkeleton() {
  return (
    <>
      <div
        role="status"
        className="
          absolute
          w-full
          h-full
          inset-0
          object-cover
          bg-gray-300
          animate-pulse
          dark:bg-gray-700"
      />
      <div className="absolute inset-0 bg-black opacity-75" />
    </>
  );
}

export default HeroImageSkeleton;
