import { ActionType } from './action';
import initialState from './initialState';

function servicesAboutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_SERVICES_ABOUT:
      return {
        ...state,
        servicesAbout: action.payload.servicesAbout,
      };
    case ActionType.SET_IS_LOADING_SERVICES_ABOUT:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_SERVICES_ABOUT:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default servicesAboutReducer;
