async function getHeadlineBlogs() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/blogs?sort=publishedAt:desc&populate=*&pagination[start]=0&pagination[limit]=5`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export default getHeadlineBlogs;
