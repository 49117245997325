import React from 'react';
import AboutUsServiceItem from './AboutUsServiceItem';
import getAboutUsServicesData from '../../data/local/getAboutServicesData';

function AboutUsServiceList() {
  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center">
      {getAboutUsServicesData.map((service) => (
        <li key={service.id}>
          <AboutUsServiceItem service={service} />
        </li>
      ))}
    </ul>
  );
}

export default AboutUsServiceList;
