import postSubscriber from '../../data/remote/collection/postSubscriber';
import {
  setIsErrorSubscribeActionCreator,
  setIsLoadingSubscribeActionCreator,
  setIsSuccessSubscribeActionCreator,
} from './action';

function asyncPostSubscriber(email) {
  return async (dispatch) => {
    dispatch(setIsLoadingSubscribeActionCreator(true));
    try {
      const { data } = await postSubscriber(email);
      if (data === null) {
        dispatch(setIsErrorSubscribeActionCreator(true));
        dispatch(setIsSuccessSubscribeActionCreator(false));
      } else {
        dispatch(setIsSuccessSubscribeActionCreator(true));
        dispatch(setIsErrorSubscribeActionCreator(false));
      }
    } catch (error) {
      dispatch(setIsSuccessSubscribeActionCreator(false));
      dispatch(setIsErrorSubscribeActionCreator(true));
    } finally {
      dispatch(setIsLoadingSubscribeActionCreator(false));
    }
  };
}

export default asyncPostSubscriber;
