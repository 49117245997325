import getPrivacyPolicy from '../../data/remote/single/getPrivacyPolicy';
import {
  receivePrivacyPolicyActionCreator,
  setIsErrorPrivacyPolicyActionCreator,
  setIsLoadingPrivacyPolicyActionCreator,
} from './action';

function asyncReceivePrivacyPolicy() {
  return async (dispatch) => {
    dispatch(setIsLoadingPrivacyPolicyActionCreator(true));
    try {
      const { data } = await getPrivacyPolicy();
      dispatch(receivePrivacyPolicyActionCreator(data));
      dispatch(setIsErrorPrivacyPolicyActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorPrivacyPolicyActionCreator(true));
    } finally {
      dispatch(setIsLoadingPrivacyPolicyActionCreator(false));
    }
  };
}

export default asyncReceivePrivacyPolicy;
