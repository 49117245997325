import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkOne({ text, destination }) {
  return (
    <li>
      <Link to={destination}>{text}</Link>
    </li>
  );
}

TextLinkOne.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkOne;
