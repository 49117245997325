import React from 'react';
import PropTypes from 'prop-types';
import ButtonText from '../atoms/ButtonText';

function TopicItem({ name, onClickTopic, params }) {
  return (
    <div
      className={`${
        params === name ? 'bg-black text-white' : 'bg-[#F2F2F2]'
      } 'bg-[#F2F2F2]'
        rounded-full
        text-sm
        md:text-base
        font-medium
        hover:shadow-md
        duration-150
        flex`}
    >
      <ButtonText label={name} action={() => onClickTopic(name)} />
    </div>
  );
}

TopicItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
};

TopicItem.defaultProps = {
  params: null,
};

export default TopicItem;
