import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  asyncReceiveInitialBlogs,
  asyncSetMoreBlogs,
} from '../../stores/blogs/thunk';
import asyncReceiveBlogDetail from '../../stores/blogDetail/thunk';
import asyncReceiveAllTopics from '../../stores/topics/thunk';
import Header from '../molecules/Header';
import MainDetailBlog from '../organisms/MainDetailBlog';

function DetailBlogPage() {
  const {
    blogs,
    isLoading: isLoadingBlogs,
    isError: isErrorBlogs,
    page,
    isNull,
  } = useSelector((states) => states.blogs);
  const {
    topics,
    isLoading: isLoadingTopics,
    isError: isErrorTopics,
  } = useSelector((states) => states.topics);
  const {
    blogDetail,
    isLoading: isLoadingBlogDetail,
    isError: isErrorBlogDetail,
  } = useSelector((states) => states.blogDetail);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get('topic');
  const blogsList = blogs.filter((blog) => blog.attributes.slug !== slug);
  const filteredBlogs = blogs.filter(
    (blog) => blog.attributes.topics.data[0]?.attributes.name.includes(params)
      && blog.attributes.slug !== slug,
  );
  const onClickTopic = (topic) => (params === topic ? setSearchParams('')
    : setSearchParams({ topic }));
  const onClickLoadMore = () => dispatch(asyncSetMoreBlogs(page));

  useEffect(() => {
    dispatch(asyncReceiveBlogDetail(slug));
    dispatch(asyncReceiveAllTopics());
    if (blogs.length === 0) dispatch(asyncReceiveInitialBlogs(page));
  }, [dispatch, slug]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <Header />
      <MainDetailBlog
        blogDetail={blogDetail}
        isLoadingBlogDetail={isLoadingBlogDetail}
        isErrorBlogDetail={isErrorBlogDetail}
        topics={topics}
        onClickTopic={onClickTopic}
        params={params}
        isLoadingTopics={isLoadingTopics}
        isErrorTopics={isErrorTopics}
        filteredBlogs={filteredBlogs}
        blogsList={blogsList}
        isLoadingBlogs={isLoadingBlogs}
        isErrorBlogs={isErrorBlogs}
        isNullBlogs={isNull}
        onClickLoadMore={onClickLoadMore}
      />
    </motion.div>
  );
}

export default DetailBlogPage;
