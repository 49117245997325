import getAbout from '../../data/remote/single/getAbout';
import {
  receiveAboutActionCreator,
  setIsErrorAboutActionCreator,
  setIsLoadingAboutActionCreator,
} from './action';

function asyncReceiveAbout() {
  return async (dispatch) => {
    dispatch(setIsLoadingAboutActionCreator(true));
    try {
      const { data } = await getAbout();
      dispatch(receiveAboutActionCreator(data));
      dispatch(setIsErrorAboutActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorAboutActionCreator(true));
    } finally {
      dispatch(setIsLoadingAboutActionCreator(false));
    }
  };
}

export default asyncReceiveAbout;
