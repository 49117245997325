import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import asyncPostEmail from '../../stores/emails/thunk';
import asyncReceiveContact from '../../stores/contact/thunk';
import HeaderContactUs from '../organisms/HeaderContactUs';
import MainContactUs from '../organisms/MainContactUs';
import HeroTwo from '../../assets/videos/hero-two.mp4';
import Footer from '../organisms/Footer';

function ContactUsPage({
  onSubscribe,
  isLoadingSubscribers,
  isSuccessSubscribers,
  isErrorSubscribers,
}) {
  const dispatch = useDispatch();
  const { contact, isLoading, isError } = useSelector(
    (states) => states.contact,
  );
  const { isLoading: isLoadingEmail, isSuccess } = useSelector(
    (states) => states.emails,
  );
  const onEmail = (email, message) => dispatch(asyncPostEmail(email, message));

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    dispatch(asyncReceiveContact());
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <HeaderContactUs
        heroMedia={
          contact !== null
          && contact?.length !== 0
          && contact?.attributes.hero_media?.data?.attributes?.url !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${contact?.attributes.hero_media.data?.attributes?.url}`
            : HeroTwo
        }
        isLoading={isLoading}
        isError={isError}
      />
      <MainContactUs
        onEmail={onEmail}
        isLoading={isLoadingEmail}
        isSuccess={isSuccess}
      />
      <Footer
        onSubscribe={onSubscribe}
        isLoading={isLoadingSubscribers}
        isSuccess={isSuccessSubscribers}
        isError={isErrorSubscribers}
      />
    </motion.div>
  );
}

ContactUsPage.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  isLoadingSubscribers: PropTypes.bool,
  isSuccessSubscribers: PropTypes.bool,
  isErrorSubscribers: PropTypes.bool,
};

ContactUsPage.defaultProps = {
  isLoadingSubscribers: null,
  isSuccessSubscribers: null,
  isErrorSubscribers: null,
};

export default ContactUsPage;
