import initialState from './initialState';
import { ActionType } from './action';

function blogDetailReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_BLOG_DETAIL:
      return {
        ...state,
        blogDetail: action.payload.blogDetail,
      };
    case ActionType.CLEAR_BLOG_DETAIL:
      return {
        ...state,
        blogDetail: null,
      };
    case ActionType.SET_IS_LOADING_BLOG_DETAIL:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_BLOG_DETAIL:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default blogDetailReducer;
