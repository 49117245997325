const ActionType = {
  RECEIVE_INITIAL_BLOGS: 'RECEIVE_INITIAL_BLOGS',
  SET_MORE_BLOGS: 'SET_MORE_BLOGS',
  SET_IS_LOADING_BLOGS: 'SET_IS_LOADING_BLOGS',
  SET_IS_ERROR_BLOGS: 'SET_IS_ERROR_BLOGS',
  SET_PAGE_BLOGS: 'SET_PAGE_BLOGS',
  SET_IS_NULL_BLOGS: 'SET_IS_NULL_BLOGS',
};

function receiveInitialBlogsActionCreator(blogs) {
  return {
    type: ActionType.RECEIVE_INITIAL_BLOGS,
    payload: {
      blogs,
    },
  };
}

function setMoreBlogsActionCreator(blogs) {
  return {
    type: ActionType.SET_MORE_BLOGS,
    payload: {
      blogs,
    },
  };
}

function setIsLoadingBlogsActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_BLOGS,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorBlogsActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_BLOGS,
    payload: {
      isError,
    },
  };
}

function setPageBlogsActionCreator(page) {
  return {
    type: ActionType.SET_PAGE_BLOGS,
    payload: {
      page,
    },
  };
}

function setIsNullBlogsActionCreator(isNull) {
  return {
    type: ActionType.SET_IS_NULL_BLOGS,
    payload: {
      isNull,
    },
  };
}

export {
  ActionType,
  receiveInitialBlogsActionCreator,
  setMoreBlogsActionCreator,
  setIsLoadingBlogsActionCreator,
  setIsErrorBlogsActionCreator,
  setPageBlogsActionCreator,
  setIsNullBlogsActionCreator,
};
