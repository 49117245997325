const ActionType = {
  RECEIVE_ABOUT: 'RECEIVE_ABOUT',
  SET_IS_LOADING_ABOUT: 'SET_IS_LOADING_ABOUT',
  SET_IS_ERROR_ABOUT: 'SET_IS_ERROR_ABOUT',
};

function receiveAboutActionCreator(about) {
  return {
    type: ActionType.RECEIVE_ABOUT,
    payload: {
      about,
    },
  };
}

function setIsLoadingAboutActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_ABOUT,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorAboutActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_ABOUT,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveAboutActionCreator,
  setIsLoadingAboutActionCreator,
  setIsErrorAboutActionCreator,
};
