async function postSubscriber(email) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/subscribers`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            email,
          },
        }),
      },
    );
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export default postSubscriber;
