import React from 'react';
import PropTypes from 'prop-types';
import HeroItem from '../molecules/HeroItem';
import HeroTitle from '../molecules/HeroTitle';

function HeaderAboutUs({ heroMedia, isLoading }) {
  return (
    <header className="relative h-[50vh] md:h-screen">
      <HeroItem video={heroMedia} isLoading={isLoading} />
      <HeroTitle title="ABOUT US" />
    </header>
  );
}

HeaderAboutUs.propTypes = {
  heroMedia: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

HeaderAboutUs.defaultProps = {
  isLoading: null,
};

export default HeaderAboutUs;
