const ActionType = {
  RECEIVE_CONTACT: 'RECEIVE_CONTACT',
  SET_IS_LOADING_CONTACT: 'SET_IS_LOADING_CONTACT',
  SET_IS_ERROR_CONTACT: 'SET_IS_ERROR_CONTACT',
};

function receiveContactActionCreator(contact) {
  return {
    type: ActionType.RECEIVE_CONTACT,
    payload: {
      contact,
    },
  };
}

function setIsLoadingContactActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_CONTACT,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorContactActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_CONTACT,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveContactActionCreator,
  setIsLoadingContactActionCreator,
  setIsErrorContactActionCreator,
};
