import React from 'react';
import { Link } from 'react-router-dom';

function CopyrightItem() {
  return (
    <p className="text-sm md:text-base">
      &copy;
      {' '}
      {new Date().getFullYear()}
      <Link to="/"> Maton Advisory </Link>
      All rights reserved
    </p>
  );
}

export default CopyrightItem;
