import React from 'react';
import PropTypes from 'prop-types';
import ContactUsInput from '../molecules/ContactUsInput';
import TextHeadingSection from '../atoms/TextHeadingSection';

function MainContactUsSection({ onEmail, isLoading, isSuccess }) {
  return (
    <section className="container m-auto flex flex-col items-center">
      <div className="flex flex-col gap-5 text-center px-5 pt-8 md:py-16 md:mt-16">
        <TextHeadingSection text="Interested In Working Together?" />
        <p className="text-sm md:text-base lg:text-lg xl:text-xl font-semibold">
          We’d love to hear from you
        </p>
      </div>
      <div className="py-8 md:py-16 px-5 w-full">
        <ContactUsInput
          onEmail={onEmail}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
    </section>
  );
}

MainContactUsSection.propTypes = {
  onEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default MainContactUsSection;
