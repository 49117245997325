import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import asyncReceiveAllClients from '../../stores/clients/thunk';
import asyncReceiveAbout from '../../stores/about/thunk';
import HeaderAboutUs from '../organisms/HeaderAboutUs';
import HeroTwo from '../../assets/videos/hero-two.mp4';
import MainAboutUs from '../organisms/MainAboutUs';
import ImageOne from '../../assets/images/image-one.svg';
import ImageTwo from '../../assets/images/image-two.jpeg';
import Footer from '../organisms/Footer';
import asyncReceiveServicesAbout from '../../stores/servicesAbout/thunk';

function AboutUsPage({
  onSubscribe,
  isLoadingSubscribers,
  isSuccessSubscribers,
  isErrorSubscribers,
}) {
  const dispatch = useDispatch();
  const {
    about,
    isLoading: isLoadingAbout,
    isError: isErrorAbout,
  } = useSelector((states) => states.about);
  const {
    servicesAbout,
    isLoading: isLoadingServicesAbout,
    isError: isErrorServicesAbout,
  } = useSelector((states) => states.servicesAbout);
  const {
    clients,
    isLoading: isLoadingClients,
    isError: isErrorClients,
  } = useSelector((states) => states.clients);

  useEffect(() => {
    dispatch(asyncReceiveAllClients());
    dispatch(asyncReceiveAbout());
    dispatch(asyncReceiveServicesAbout());
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <HeaderAboutUs
        heroMedia={
          about !== null
          && about?.length !== 0
          && about?.attributes.hero_media?.data?.attributes?.url !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${about?.attributes.hero_media.data?.attributes?.url}`
            : HeroTwo
        }
        isLoading={isLoadingAbout}
      />
      <MainAboutUs
        aboutHeading={
          about !== null
          && about?.length !== 0
          && about?.attributes.about_heading !== null
            ? about?.attributes.about_heading
            : 'Maton Advisory'
        }
        aboutBody={
          about !== null
          && about?.length !== 0
          && about?.attributes.about_body !== null
            ? about?.attributes.about_body
            : 'Maton Advisory helps organizations and companies engage in public policy discourse and formulation, build and maintain strong reputations, promote interest and agenda, and find common ground with key stakeholders. We are in the business of relationships.'
        }
        firstQuoteImage={
          about !== null
          && about?.length !== 0
          && about?.attributes.first_quote_image?.data?.attributes?.url
            !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${about?.attributes.first_quote_image?.data?.attributes?.url}`
            : ImageOne
        }
        firstQuoteContent={
          about !== null
          && about?.length !== 0
          && about?.attributes.first_quote_content !== null
            ? about?.attributes.first_quote_content
            : `A public relations practitioner with 15 years of experience in strategic communications, media training, public affairs, government relations, and crisis management. He was senior consultant of public affairs at IndoPacific Edelman (now Edelman Indonesia (2007-2010, handling clients from energy to finance, and assisted banks and financial institutions during the 2008 financial crisis.
              
            Dadi was chief editor of Bloomberg Businessweek Indonesia, a licensed business magazine and portal of Bloomberg LP in New York (2010). He was a technical advisor in communication to the Minister of Trade from 2016 to 2019`
        }
        firstQuoteSpeaker={
          about !== null
          && about?.length !== 0
          && about?.attributes.first_quote_speaker !== null
            ? about?.attributes.first_quote_speaker
            : 'Dadi Krismatono'
        }
        firstQuoteSpeakerTitle={
          about !== null
          && about?.length !== 0
          && about?.attributes.first_quote_speaker_title !== null
            ? about?.attributes.first_quote_speaker_title
            : 'CEO & Principal Consultant'
        }
        secondQuoteImage={
          about !== null
          && about?.length !== 0
          && about?.attributes.second_quote_image?.data?.attributes?.url
            !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${about?.attributes.second_quote_image?.data?.attributes?.url}`
            : ImageOne
        }
        secondQuoteContent={
          about !== null
          && about?.length !== 0
          && about?.attributes.second_quote_content !== null
            ? about?.attributes.second_quote_content
            : `A Lorem ipsum dolor sit amet. Et molestias inventore eum accusantium magnam et ratione numquam qui quia nihil eos facilis enim ut libero sunt? Est molestias explicabo sit quidem perferendis aut sint voluptates qui modi adipisci.

            Aut doloremque incidunt sit expedita accusamus hic molestiae exercitationem ex illum molestiae in beatae voluptas. Et quas ullam nam maxime repellat non molestiae maiores qui nisi provident At autem laborum 33 tenetur tempore qui commodi repellat. `
        }
        secondQuoteSpeaker={
          about !== null
          && about?.length !== 0
          && about?.attributes.second_quote_speaker !== null
            ? about?.attributes.second_quote_speaker
            : 'Prima Harrison'
        }
        secondQuoteSpeakerTitle={
          about !== null
          && about?.length !== 0
          && about?.attributes.second_quote_speaker_title !== null
            ? about?.attributes.second_quote_speaker_title
            : 'Senior Associate'
        }
        servicesImage={
          about !== null
          && about?.length !== 0
          && about?.attributes.services_image?.data?.attributes?.url !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${about?.attributes.services_image.data?.attributes?.url}`
            : ImageTwo
        }
        isLoadingAbout={isLoadingAbout}
        isErrorAbout={isErrorAbout}
        services={servicesAbout}
        isLoadingServices={isLoadingServicesAbout}
        isErrorServices={isErrorServicesAbout}
        clients={clients}
        isLoadingClients={isLoadingClients}
        isErrorClients={isErrorClients}
      />
      <Footer
        onSubscribe={onSubscribe}
        isLoading={isLoadingSubscribers}
        isSuccess={isSuccessSubscribers}
        isError={isErrorSubscribers}
      />
    </motion.div>
  );
}

AboutUsPage.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  isLoadingSubscribers: PropTypes.bool,
  isSuccessSubscribers: PropTypes.bool,
  isErrorSubscribers: PropTypes.bool,
};

AboutUsPage.defaultProps = {
  isLoadingSubscribers: null,
  isSuccessSubscribers: null,
  isErrorSubscribers: null,
};

export default AboutUsPage;
