import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkFive({ text, destination }) {
  return (
    <Link
      to={destination}
      className="
      hover:underline
      hover:decoration-4
      hover:underline-offset-8
      hover:decoration-[#2F80ED]"
    >
      {text}
    </Link>
  );
}

TextLinkFive.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkFive;
