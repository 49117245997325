import getAllTopics from '../../data/remote/collection/getAllTopics';
import {
  receiveAllTopicsActionCreator,
  setIsErrorTopicsActionCreator,
  setIsLoadingTopicsActionCreator,
} from './action';

function asyncReceiveAllTopics() {
  return async (dispatch) => {
    dispatch(setIsLoadingTopicsActionCreator(true));
    try {
      const { data } = await getAllTopics();
      dispatch(receiveAllTopicsActionCreator(data));
      dispatch(setIsErrorTopicsActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorTopicsActionCreator(true));
    } finally {
      dispatch(setIsLoadingTopicsActionCreator(false));
    }
  };
}

export default asyncReceiveAllTopics;
