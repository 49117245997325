import React from 'react';
import PropTypes from 'prop-types';
import FeatherSend from '../../assets/icons/feather-send.svg';

function SendButton({
  action,
  emailValue,
  messageValue,
  reCaptcha,
  isLoading,
}) {
  return (
    <button
      type="button"
      className={`${
        emailValue === '' || messageValue === '' || reCaptcha === ''
          ? 'bg-[#808080]'
          : 'bg-[#08347D]'
      } flex gap-2 items-center px-3 py-1 rounded-md text-white font-semibold`}
      onClick={() => action(emailValue, messageValue)}
      disabled={
        emailValue === ''
        || messageValue === ''
        || reCaptcha === ''
        || isLoading
      }
    >
      {isLoading ? (
        <div className=" flex justify-center items-center w-36">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white" />
        </div>
      ) : (
        <>
          Send message
          <img src={FeatherSend} alt="Feather send icon" width={15} />
        </>
      )}
    </button>
  );
}

SendButton.propTypes = {
  action: PropTypes.func.isRequired,
  emailValue: PropTypes.string.isRequired,
  messageValue: PropTypes.string.isRequired,
  reCaptcha: PropTypes.string.isRequired,
  isLoading: PropTypes.string.isRequired,
};

export default SendButton;
