import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbListOne from '../molecules/BlogThumbListOne';
import TextHeadingSection from '../atoms/TextHeadingSection';
import blogShape from '../../utils/propHelper/blogShape';

function MainHomeThirdSection({ headlineBlogs, isLoading, isError }) {
  return (
    <section className="container m-auto flex flex-col gap-20 py-[40px] md:py-[80px] items-center">
      <TextHeadingSection text="Maton Highlights" />
      <BlogThumbListOne
        headlineBlogs={headlineBlogs}
        isLoading={isLoading}
        isError={isError}
      />
    </section>
  );
}

MainHomeThirdSection.propTypes = {
  headlineBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)).isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

MainHomeThirdSection.defaultProps = {
  isLoading: null,
  isError: null,
};

export default MainHomeThirdSection;
