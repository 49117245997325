import React from 'react';
import PropTypes from 'prop-types';
import MainHomeFirstSection from './MainHomeFirstSection';
import MainHomeSecondSection from './MainHomeSecondSection';
import MainHomeThirdSection from './MainHomeThirdSection';
import MainHomeFourthSection from './MainHomeFourthSection';
import homeServiceShape from '../../utils/propHelper/homeServiceShape';
import blogShape from '../../utils/propHelper/blogShape';

function MainHome({
  aboutHeading,
  aboutBody,
  isLoadingHome,
  services,
  isLoadingServices,
  isErrorServices,
  headlineBlogs,
  isLoadingHeadlineBlogs,
  isErrorHeadlineBlogs,
}) {
  return (
    <main>
      <MainHomeFirstSection
        aboutHeading={aboutHeading}
        aboutBody={aboutBody}
        isLoading={isLoadingHome}
      />
      <MainHomeSecondSection
        services={services}
        isLoadingServices={isLoadingServices}
        isErrorServices={isErrorServices}
      />
      <MainHomeFourthSection />
      <MainHomeThirdSection
        headlineBlogs={headlineBlogs}
        isLoading={isLoadingHeadlineBlogs}
        isError={isErrorHeadlineBlogs}
      />
    </main>
  );
}

MainHome.propTypes = {
  aboutHeading: PropTypes.string.isRequired,
  aboutBody: PropTypes.string.isRequired,
  isLoadingHome: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape(homeServiceShape)),
  isLoadingServices: PropTypes.bool,
  isErrorServices: PropTypes.bool,
  headlineBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)).isRequired,
  isLoadingHeadlineBlogs: PropTypes.bool,
  isErrorHeadlineBlogs: PropTypes.bool,
};

MainHome.defaultProps = {
  isLoadingHome: null,
  services: null,
  isLoadingServices: null,
  isErrorServices: null,
  isLoadingHeadlineBlogs: null,
  isErrorHeadlineBlogs: null,
};

export default MainHome;
