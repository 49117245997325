import React from 'react';

function HomeServiceItemSkeleton() {
  return (
    <div
      role="status"
      className="
        space-y-8
        animate-pulse
        md:space-y-0
        md:space-x-8
        rtl:space-x-reverse
        md:flex
        md:items-center"
    >
      <div
        className="
          flex
          items-center
          justify-center
          w-full
          max-w-96
          h-36
          bg-gray-300
          rounded
          sm:w-[360px]
          dark:bg-gray-700"
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default HomeServiceItemSkeleton;
