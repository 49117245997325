import React from 'react';
import PropTypes from 'prop-types';

function TextBody({ text }) {
  return text.split('\n\n\n').map((paragraph) => (
    <p
      key={paragraph}
      className="
        font-lato
        text-xs
        leading-relaxed
        md:text-xl
        md:leading-relaxed
        max-w-[924px]
        m-auto"
    >
      {paragraph
        .split('\n')
        .reduce((total, line) => [total, <br key={line} />, line])}
    </p>
  ));
}

TextBody.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextBody;
