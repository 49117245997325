const getAboutUsServicesData = [
  {
    id: 1,
    name: 'Public policy research and analysis',
  },
  {
    id: 2,
    name: 'Monitoring legislative and regulatory developments',
  },
  {
    id: 3,
    name: 'Developing and implementing advocacy campaigns',
  },
  {
    id: 4,
    name: 'Drafting policy briefs, position papers, and other communications materials',
  },
  {
    id: 5,
    name: 'Building relationships with policymakers, stakeholders, and the media',
  },
  {
    id: 6,
    name: 'Organizing events and meetings to engage with policymakers and stakeholders',
  },
  {
    id: 7,
    name: 'Providing strategic advice to clients on public policy issues',
  },
  {
    id: 8,
    name: 'Managing coalitions and alliances to advance policy goals',
  },
];

export default getAboutUsServicesData;
