import initialState from './initialState';
import { ActionType } from './action';

function topicsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_ALL_TOPICS:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case ActionType.SET_IS_LOADING_TOPICS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_TOPICS:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default topicsReducer;
