import React from 'react';
import NotFound from '../molecules/NotFound';

function MainNotFound() {
  return (
    <main className="container m-auto py-32 px-10">
      <NotFound />
    </main>
  );
}

export default MainNotFound;
