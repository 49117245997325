import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkSix({ text, destination }) {
  return (
    <Link
      to={destination}
      className="
      underline
      decoration-4
      underline-offset-8
      decoration-[#2F80ED]
      w-full
      text-center"
    >
      {text}
    </Link>
  );
}

TextLinkSix.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkSix;
