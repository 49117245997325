import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkSeven({ text, destination }) {
  return (
    <Link
      to={destination}
      className="
      hover:underline
      hover:decoration-4
      hover:underline-offset-8
      hover:decoration-[#2F80ED]
      w-full
      text-center"
    >
      {text}
    </Link>
  );
}

TextLinkSeven.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkSeven;
