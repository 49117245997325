import React from 'react';

function TopicItemSkeleton() {
  return (
    <div
      role="status"
      className="
        space-y-8
        animate-pulse
        md:space-y-0
        md:space-x-8
        rtl:space-x-reverse
        md:flex
        md:items-center"
    >
      {' '}
      <div
        className="
          flex
          items-center
          justify-center
          w-full
          h-12
          bg-gray-300
          rounded-full
          sm:w-40
          dark:bg-gray-700"
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default TopicItemSkeleton;
