import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

function PrivacyPolicyItem({ privacyPolicyHeading, privacyPolicyBody }) {
  return (
    <div className="flex flex-col gap-10 items-center">
      <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold">
        {privacyPolicyHeading}
      </h2>
      <ReactMarkdown
        className="
          text-sm
          md:text-base
          lg:text-lg
          xl:text-xl
          text-[#757575]
          space-y-5"
      >
        {privacyPolicyBody}
      </ReactMarkdown>
    </div>
  );
}

PrivacyPolicyItem.propTypes = {
  privacyPolicyHeading: PropTypes.string.isRequired,
  privacyPolicyBody: PropTypes.string.isRequired,
};

export default PrivacyPolicyItem;
