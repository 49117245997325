import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigation from './Navigation';
import NavigationDrawer from './NavigationDrawer';

function TopNavigation({ isOpen }) {
  const { pathname } = useLocation();

  return (
    <nav>
      <Navigation pathname={pathname} />
      {isOpen ? <NavigationDrawer pathname={pathname} /> : ''}
    </nav>
  );
}

TopNavigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default TopNavigation;
