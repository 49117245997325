const ActionType = {
  SET_IS_LOADING_EMAIL: 'SET_IS_LOADING_EMAIL',
  SET_IS_SUCCESS_EMAIL: 'SET_IS_SUCCESS_EMAIL',
  SET_IS_ERROR_EMAIL: 'SET_IS_ERROR_EMAIL',
};

function setIsLoadingEmailActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_EMAIL,
    payload: {
      isLoading,
    },
  };
}

function setIsSuccessEmailActionCreator(isSuccess) {
  return {
    type: ActionType.SET_IS_SUCCESS_EMAIL,
    payload: {
      isSuccess,
    },
  };
}

function setIsErrorEmailActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_EMAIL,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  setIsLoadingEmailActionCreator,
  setIsSuccessEmailActionCreator,
  setIsErrorEmailActionCreator,
};
