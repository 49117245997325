import React from 'react';
import PropTypes from 'prop-types';

function TextHeadingAside({ text }) {
  return (
    <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">
      {text}
    </h3>
  );
}

TextHeadingAside.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextHeadingAside;
