import React from 'react';
import PropTypes from 'prop-types';
import TopicListOne from '../molecules/TopicListOne';
import BlogFooter from '../molecules/BlogFooter';
import topicShape from '../../utils/propHelper/topicShape';

function Sidebar({
  topics, onClickTopic, params, isLoading, isError,
}) {
  return (
    <div className="flex flex-col gap-10">
      <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">
        See Other Topics
      </h3>
      <TopicListOne
        topics={topics}
        onClickTopic={onClickTopic}
        params={params}
        isLoading={isLoading}
        isError={isError}
      />
      <div className="flex flex-col gap-5">
        <hr className="bg-gray-100 border-1" />
        <div className="flex justify-center">
          <BlogFooter />
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

Sidebar.defaultProps = {
  topics: null,
  params: null,
  isLoading: null,
  isError: null,
};

export default Sidebar;
