import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { formattedDate } from '../../utils/common';
import SocialIconList from './SocialIconList';
import NoImageAvailable from '../../assets/images/no-image-available.png';
import BlogDetailTitleSkeleton from '../skeletons/BlogDetailTitleSkeleton';
import BlogDetailDateSkeleton from '../skeletons/BlogDetailDateSkeleton';
import BlogDetailImageSkeleton from '../skeletons/BlogDetailImageSkeleton';
import BlogDetailContentSkeleton from '../skeletons/BlogDetailContentSkeleton';
import blogShape from '../../utils/propHelper/blogShape';

function BlogDetailContent({ blog, isLoading }) {
  return (
    <div className="flex flex-col gap-5">
      {isLoading ? (
        <BlogDetailTitleSkeleton />
      ) : (
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold">
          {blog?.attributes?.title}
        </h2>
      )}
      <div className="flex flex-col md:flex-row md:items-center gap-5 text-[#757575]">
        {isLoading ? (
          <BlogDetailDateSkeleton />
        ) : (
          <p className="text-sm md:text-base lg:text-lg xl:text-xl">
            {formattedDate(blog?.attributes?.publishedAt)}
          </p>
        )}
        <SocialIconList />
      </div>
      {isLoading ? (
        <BlogDetailImageSkeleton />
      ) : (
        <img
          src={
            blog?.attributes.image.data?.attributes?.url
              ? `${process.env.REACT_APP_BASE_URL}${blog?.attributes?.image?.data?.attributes?.url}`
              : NoImageAvailable
          }
          alt="Blog's img"
          className="w-full h-full object-contain rounded-md"
        />
      )}
      {isLoading ? (
        <BlogDetailContentSkeleton />
      ) : (
        <ReactMarkdown className="text-sm md:text-base lg:text-lg xl:text-xl text-[#757575] space-y-5">
          {blog?.attributes?.body}
        </ReactMarkdown>
      )}
    </div>
  );
}

BlogDetailContent.propTypes = {
  blog: PropTypes.shape(blogShape),
  isLoading: PropTypes.bool,
};

BlogDetailContent.defaultProps = {
  blog: null,
  isLoading: null,
};

export default BlogDetailContent;
