async function getHome() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/home?populate=*`,
    );
    return response.json();
  } catch (error) {
    throw new Error(error.message);
  }
}

export default getHome;
