import initialState from './initialState';
import { ActionType } from './action';

function emailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.SET_IS_LOADING_EMAIL:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_SUCCESS_EMAIL:
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    case ActionType.SET_IS_ERROR_EMAIL:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default emailsReducer;
