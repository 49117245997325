import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function IconLink({ icon, label, destination }) {
  return (
    <li>
      <Link to={destination}>
        <img src={icon} alt={label} className="w-5 md:w-6 lg:w-7 xl:w-8" />
      </Link>
    </li>
  );
}

IconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default IconLink;
