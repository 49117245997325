const ActionType = {
  RECEIVE_PRIVACY_POLICY: 'RECEIVE_PRIVACY_POLICY',
  SET_IS_LOADING_PRIVACY_POLICY: 'SET_IS_LOADING_PRIVACY_POLICY',
  SET_IS_ERROR_PRIVACY_POLICY: 'SET_IS_ERROR_PRIVACY_POLICY',
};

function receivePrivacyPolicyActionCreator(privacyPolicy) {
  return {
    type: ActionType.RECEIVE_PRIVACY_POLICY,
    payload: {
      privacyPolicy,
    },
  };
}

function setIsLoadingPrivacyPolicyActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_PRIVACY_POLICY,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorPrivacyPolicyActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_PRIVACY_POLICY,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receivePrivacyPolicyActionCreator,
  setIsLoadingPrivacyPolicyActionCreator,
  setIsErrorPrivacyPolicyActionCreator,
};
