import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  asyncReceiveInitialBlogs,
  asyncSetMoreBlogs,
} from '../../stores/blogs/thunk';
import asyncReceiveHeadlineBlogs from '../../stores/headlineBlogs/thunk';
import asyncReceiveAllTopics from '../../stores/topics/thunk';
import HeaderBlogPage from '../organisms/HeaderBlogPage';
import MainBlogPage from '../organisms/MainBlogPage';

function BlogPage() {
  const {
    headlineBlogs,
    isLoading: isLoadingHeadlineBlogs,
    isError: isErrorHeadlineBlogs,
  } = useSelector((states) => states.headlineBlogs);
  const {
    blogs,
    isLoading: isLoadingBlogs,
    isError: isErrorBlogs,
    page,
    isNull,
  } = useSelector((states) => states.blogs);
  const {
    topics,
    isLoading: isLoadingTopics,
    isError: isErrorTopics,
  } = useSelector((states) => states.topics);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get('topic');
  const filteredBlogs = blogs?.filter(
    (blog) => blog.attributes.topics.data.some((topic) => topic.attributes.name.includes(params)),
  );
  const onClickTopic = (topic) => (params === topic ? setSearchParams('')
    : setSearchParams({ topic }));
  const onClickLoadMore = () => dispatch(asyncSetMoreBlogs(page));

  useEffect(() => {
    dispatch(asyncReceiveHeadlineBlogs());
    dispatch(asyncReceiveAllTopics());
    if (blogs?.length === 0) dispatch(asyncReceiveInitialBlogs(page));
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <HeaderBlogPage />
      <MainBlogPage
        headlineBlogs={headlineBlogs}
        isLoadingHeadlineBlogs={isLoadingHeadlineBlogs}
        isErrorHeadlineBlogs={isErrorHeadlineBlogs}
        topics={topics}
        onClickTopic={onClickTopic}
        params={params}
        isLoadingTopics={isLoadingTopics}
        isErrorTopics={isErrorTopics}
        filteredBlogs={filteredBlogs}
        blogs={blogs}
        isLoadingBlogs={isLoadingBlogs}
        isErrorBlogs={isErrorBlogs}
        isNullBlogs={isNull}
        onClickLoadMore={onClickLoadMore}
      />
    </motion.div>
  );
}

export default BlogPage;
