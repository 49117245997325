import getContact from '../../data/remote/single/getContact';
import {
  receiveContactActionCreator,
  setIsErrorContactActionCreator,
  setIsLoadingContactActionCreator,
} from './action';

function asyncReceiveContact() {
  return async (dispatch) => {
    dispatch(setIsLoadingContactActionCreator(true));
    try {
      const { data } = await getContact();
      dispatch(receiveContactActionCreator(data));
      dispatch(setIsErrorContactActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorContactActionCreator(true));
    } finally {
      dispatch(setIsLoadingContactActionCreator(false));
    }
  };
}

export default asyncReceiveContact;
