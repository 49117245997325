import React from 'react';
import PropTypes from 'prop-types';

function TextHeroHeading({ text }) {
  return (
    <h2 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold">
      {text}
    </h2>
  );
}

TextHeroHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextHeroHeading;
