const ActionType = {
  RECEIVE_SERVICES_HOME: 'RECEIVE_SERVICES_HOME',
  SET_IS_LOADING_SERVICES_HOME: 'SET_IS_LOADING_SERVICES_HOME',
  SET_IS_ERROR_SERVICES_HOME: 'SET_IS_ERROR_SERVICES_HOME',
};

function receiveServicesHomeActionCreator(servicesHome) {
  return {
    type: ActionType.RECEIVE_SERVICES_HOME,
    payload: {
      servicesHome,
    },
  };
}

function setIsLoadingServicesHomeActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_SERVICES_HOME,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorServicesHomeActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_SERVICES_HOME,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveServicesHomeActionCreator,
  setIsLoadingServicesHomeActionCreator,
  setIsErrorServicesHomeActionCreator,
};
