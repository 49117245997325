import React from 'react';
import PropTypes from 'prop-types';

function HomeServiceItem({ name, description, image }) {
  return (
    <div className="relative max-w-md text-white p-5 rounded-2xl">
      <img
        src={`${process.env.REACT_APP_BASE_URL}${image}`}
        alt="Img"
        className="absolute inset-0 w-full h-full object-cover rounded-2xl"
      />
      <div className="absolute inset-0 bg-black opacity-75 rounded-2xl" />
      <div className="flex flex-col gap-5 relative">
        <h3 className="text-lg md:text-xl lg:text-2xl font-semibold tracking-wide">
          {name}
        </h3>
        <p className="text-sm md:text-md lg:text-base leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
}

HomeServiceItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default HomeServiceItem;
