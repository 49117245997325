import React from 'react';
import SocialTextList from './SocialTextList';
import SideNavigation from './SideNavigation';
import CopyrightItem from './CopyrightItem';

function BlogFooter() {
  return (
    <footer className="flex flex-col gap-3 w-fit text-[#A5A5A5] items-center">
      <SocialTextList />
      <SideNavigation />
      <CopyrightItem />
    </footer>
  );
}

export default BlogFooter;
