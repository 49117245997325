import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import asyncReceiveHome from '../../stores/home/thunk';
import asyncReceiveHeadlineBlogs from '../../stores/headlineBlogs/thunk';
import asyncReceiveServicesHome from '../../stores/servicesHome/thunk';
import HeaderHome from '../organisms/HeaderHome';
import HeroThree from '../../assets/videos/hero-three.mp4';
import MainHome from '../organisms/MainHome';
import Footer from '../organisms/Footer';

function HomePage({
  onSubscribe,
  isLoadingSubscribers,
  isSuccessSubscribers,
  isErrorSubscribers,
}) {
  const dispatch = useDispatch();
  const {
    home,
    isLoading: isLoadingHome,
    isError: isErrorHome,
  } = useSelector((states) => states.home);
  const {
    headlineBlogs,
    isLoading: isLoadingHeadlineBlogs,
    isError: isErrorHeadlineBlogs,
  } = useSelector((states) => states.headlineBlogs);
  const {
    servicesHome,
    isLoading: isLoadingServicesHome,
    isError: isErrorServicesHome,
  } = useSelector((states) => states.servicesHome);

  useEffect(() => {
    dispatch(asyncReceiveHome());
    dispatch(asyncReceiveHeadlineBlogs());
    dispatch(asyncReceiveServicesHome());
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>Maton Advisory Public Affair & Policy Engagement</title>
        <meta
          name="description"
          content="Maton Advisory helps organizations and companies engage in public policy discourse and formulation, build and maintain strong reputations, promote interest and agenda, and find common ground with key stakeholders. We are in the business of relationships."
        />
      </Helmet>
      <HeaderHome
        heroMedia={
          home !== null
          && home?.length !== 0
          && home?.attributes.hero_media?.data?.attributes?.url !== undefined
            ? `${process.env.REACT_APP_BASE_URL}${home?.attributes.hero_media.data?.attributes?.url}`
            : HeroThree
        }
        heroHeading={
          home !== null
          && home?.length !== 0
          && home?.attributes.hero_heading !== null
            ? home?.attributes.hero_heading
            : 'We are in the business of relationships'
        }
        heroBody={
          home !== null
          && home?.length !== 0
          && home?.attributes.hero_body !== null
            ? home?.attributes.hero_body
            : 'We helps organizations and companies engage in public policy discource and formulation, build and maintain strong reputations'
        }
        isLoading={isLoadingHome}
      />
      <MainHome
        aboutHeading={
          home !== null
          && home?.length !== 0
          && home?.attributes.about_heading !== null
            ? home?.attributes.about_heading
            : 'Maton Advisory'
        }
        aboutBody={
          home !== null
          && home?.length !== 0
          && home?.attributes.about_body !== null
            ? home?.attributes.about_body
            : 'Maton Advisory helps organizations and companies engage in public policy discourse and formulation, build and maintain strong reputations, promote interest and agenda, and find common ground with key stakeholders. We are in the business of relationships.'
        }
        isLoadingHome={isLoadingHome}
        isErrorHome={isErrorHome}
        services={servicesHome}
        isLoadingServices={isLoadingServicesHome}
        isErrorServices={isErrorServicesHome}
        headlineBlogs={headlineBlogs}
        isLoadingHeadlineBlogs={isLoadingHeadlineBlogs}
        isErrorHeadlineBlogs={isErrorHeadlineBlogs}
      />
      <Footer
        onSubscribe={onSubscribe}
        isLoading={isLoadingSubscribers}
        isSuccess={isSuccessSubscribers}
        isError={isErrorSubscribers}
      />
    </motion.div>
  );
}

HomePage.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  isLoadingSubscribers: PropTypes.bool,
  isSuccessSubscribers: PropTypes.bool,
  isErrorSubscribers: PropTypes.bool,
};

HomePage.defaultProps = {
  isLoadingSubscribers: null,
  isSuccessSubscribers: null,
  isErrorSubscribers: null,
};

export default HomePage;
