const ActionType = {
  RECEIVE_BLOG_DETAIL: 'RECEIVE_BLOG_DETAIL',
  CLEAR_BLOG_DETAIL: 'CLEAR_BLOG_DETAIL',
  SET_IS_LOADING_BLOG_DETAIL: 'SET_IS_LOADING_BLOG_DETAIL',
  SET_IS_ERROR_BLOG_DETAIL: 'SET_IS_ERROR_BLOG_DETAIL',
};

function receiveBlogDetailActionCreator(blogDetail) {
  return {
    type: ActionType.RECEIVE_BLOG_DETAIL,
    payload: {
      blogDetail,
    },
  };
}

function clearBlogDetailActionCreator() {
  return {
    type: ActionType.CLEAR_BLOG_DETAIL,
  };
}

function setIsLoadingBlogDetailActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_BLOG_DETAIL,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorBlogDetailActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_BLOG_DETAIL,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveBlogDetailActionCreator,
  clearBlogDetailActionCreator,
  setIsLoadingBlogDetailActionCreator,
  setIsErrorBlogDetailActionCreator,
};
