import React from 'react';

function HeroHeadingSkeleton() {
  return (
    <div
      role="status"
      className="w-full flex flex-col items-center animate-pulse"
    >
      <div
        className="
          h-4
          md:h-8
          lg:h-12
          xl:h-16
          bg-gray-200
          rounded-full
          dark:bg-gray-700
          w-full
          max-w-[840px]
          mb-4"
      />
      <div
        className="
          h-4
          md:h-8
          lg:h-12
          xl:h-16
          bg-gray-200
          rounded-full
          dark:bg-gray-700
          w-full
          max-w-[340px]
          md:max-w-[440px]
          lg:max-w-[540px]
          xl:max-w-[640px]
          mb-2.5"
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default HeroHeadingSkeleton;
