import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbListBackgroundOne from '../../assets/images/blog-thumb-list-background-one.jpeg';
import TopicListTwo from '../molecules/TopicListTwo';
import BlogThumbListTwo from '../molecules/BlogThumbListTwo';
import Sidebar from './Sidebar';
import BlogFooter from '../molecules/BlogFooter';
import ImageBackground from '../molecules/ImageBackground';
import TextHeadingAside from '../atoms/TextHeadingAside';
import topicShape from '../../utils/propHelper/topicShape';
import blogShape from '../../utils/propHelper/blogShape';

function MainBlogPageSecondSection({
  topics,
  onClickTopic,
  params,
  isLoadingTopics,
  isErrorTopics,
  filteredBlogs,
  blogs,
  isLoadingBlogs,
  isErrorBlogs,
  isNullBlogs,
  onClickLoadMore,
}) {
  return (
    <section className="relative sm:py-16 md:px-10">
      <ImageBackground image={BlogThumbListBackgroundOne} />
      <div
        className="
          container
          m-auto
          flex
          justify-between
          gap-16
          bg-white
          px-5
          py-10
          md:p-10
          sm:rounded-lg
          relative
          md:shadow-2xl
          w-full
          2xl:w-[1440px]"
      >
        <div className="flex flex-col gap-10 w-full">
          <BlogThumbListTwo
            blogs={params ? filteredBlogs : blogs}
            params={params}
            isNull={isNullBlogs}
            isLoading={isLoadingBlogs}
            isError={isErrorBlogs}
            onClickLoadMore={onClickLoadMore}
          />
        </div>
        <aside className="hidden xl:block w-[480px]">
          <Sidebar
            topics={topics}
            onClickTopic={onClickTopic}
            params={params}
            isLoading={isLoadingTopics}
            isError={isErrorTopics}
          />
        </aside>
      </div>
      <div className="absolute px-5 flex flex-col gap-5 sm:hidden w-full">
        <TextHeadingAside text="See Other Topics" />
        <TopicListTwo
          topics={topics}
          onClickTopic={onClickTopic}
          params={params}
          isLoading={isLoadingTopics}
          isError={isErrorTopics}
        />
        <hr className="bg-gray-100 border-1" />
        <div className="flex pb-5 justify-center">
          <BlogFooter />
        </div>
      </div>
    </section>
  );
}

MainBlogPageSecondSection.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoadingTopics: PropTypes.bool,
  isErrorTopics: PropTypes.bool,
  filteredBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  blogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoadingBlogs: PropTypes.bool,
  isErrorBlogs: PropTypes.bool,
  isNullBlogs: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
};

MainBlogPageSecondSection.defaultProps = {
  topics: null,
  isLoadingTopics: null,
  isErrorTopics: null,
  params: null,
  filteredBlogs: null,
  blogs: null,
  isLoadingBlogs: null,
  isErrorBlogs: null,
  isNullBlogs: null,
};

export default MainBlogPageSecondSection;
