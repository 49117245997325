import React from 'react';
import PropTypes from 'prop-types';
import MainDetailBlogFirstSection from './MainDetailBlogFirstSection';
import MainDetailBlogSecondSection from './MainDetailBlogSecondSection';
import blogShape from '../../utils/propHelper/blogShape';
import topicShape from '../../utils/propHelper/topicShape';

function MainDetailBlog({
  blogDetail,
  isLoadingBlogDetail,
  isErrorBlogDetail,
  topics,
  onClickTopic,
  params,
  isLoadingTopics,
  isErrorTopics,
  filteredBlogs,
  blogsList,
  isLoadingBlogs,
  isErrorBlogs,
  isNullBlogs,
  onClickLoadMore,
}) {
  return (
    <main>
      <MainDetailBlogFirstSection
        blog={blogDetail}
        isLoading={isLoadingBlogDetail}
        isError={isErrorBlogDetail}
      />
      <MainDetailBlogSecondSection
        topics={topics}
        onClickTopic={onClickTopic}
        params={params}
        isLoadingTopics={isLoadingTopics}
        isErrorTopics={isErrorTopics}
        filteredBlogs={filteredBlogs}
        blogsList={blogsList}
        isLoadingBlogs={isLoadingBlogs}
        isErrorBlogs={isErrorBlogs}
        isNullBlogs={isNullBlogs}
        onClickLoadMore={onClickLoadMore}
      />
    </main>
  );
}

MainDetailBlog.propTypes = {
  blogDetail: PropTypes.shape(blogShape),
  isLoadingBlogDetail: PropTypes.bool,
  isErrorBlogDetail: PropTypes.bool,
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoadingTopics: PropTypes.bool,
  isErrorTopics: PropTypes.bool,
  filteredBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  blogsList: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoadingBlogs: PropTypes.bool,
  isErrorBlogs: PropTypes.bool,
  isNullBlogs: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
};

MainDetailBlog.defaultProps = {
  blogDetail: null,
  isLoadingBlogDetail: null,
  isErrorBlogDetail: null,
  topics: null,
  isLoadingTopics: null,
  isErrorTopics: null,
  params: null,
  filteredBlogs: null,
  blogsList: null,
  isLoadingBlogs: null,
  isErrorBlogs: null,
  isNullBlogs: null,
};

export default MainDetailBlog;
