import React from 'react';
import PropTypes from 'prop-types';

function ButtonText({ label, action }) {
  return (
    <button type="button" className="w-full px-4 py-4" onClick={action}>
      {label}
    </button>
  );
}

ButtonText.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default ButtonText;
