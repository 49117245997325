import React from 'react';
import PropTypes from 'prop-types';
import BlogDetail from '../molecules/BlogDetail';
import blogShape from '../../utils/propHelper/blogShape';

function MainDetailBlogFirstSection({ blog, isLoading, isError }) {
  return (
    <section className="container m-auto py-8 md:py-16 px-5">
      <BlogDetail blog={blog} isLoading={isLoading} isError={isError} />
    </section>
  );
}

MainDetailBlogFirstSection.propTypes = {
  blog: PropTypes.shape(blogShape),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

MainDetailBlogFirstSection.defaultProps = {
  blog: null,
  isLoading: null,
  isError: null,
};

export default MainDetailBlogFirstSection;
