import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { formattedDate, onClickBlogItem } from '../../utils/common';
import NoImageAvailable from '../../assets/images/no-image-available.png';

function BlogThumbItemFour({
  slug, date, title, image,
}) {
  return (
    <div className="md:w-[720px] justify-between overflow-hidden">
      <Link
        to={`/blogs/${slug}`}
        className="flex flex-col-reverse md:flex-row md:items-center group"
        onClick={onClickBlogItem}
      >
        <div
          className="
            p-5 font-semibold group-hover:text-[#2F80ED] text-xl group-hover:text-[1.4em] w-full"
        >
          <p
            className="
              text-xs
              group-hover:text-[.85rem]
              md:text-sm
              md:group-hover:text-[.975rem]
              lg:text-base
              lg:group-hover:text-[1.1rem]
              duration-500"
          >
            {formattedDate(date)}
          </p>
          <h2
            className="
              text-base
              group-hover:text-[1.1rem]
              md:text-lg
              md:group-hover:text-[1.225rem]
              lg:text-xl
              lg:group-hover:text-[1.35rem]
              xl:text-2xl
              xl:group-hover:text-[1.6rem]
              py-2 duration-500"
          >
            {title}
          </h2>
          <div className="flex just items-center gap-2 mt-5 text-[#2F80ED] text-lg">
            <p className="text-xs md:text-sm lg:text-base">Read more </p>
            <AiOutlineArrowRight />
          </div>
        </div>
        <div className="md:w-full overflow-hidden">
          <img
            src={
              image
                ? `${process.env.REACT_APP_BASE_URL}${image}`
                : NoImageAvailable
            }
            alt="Blog"
            className="w-[720px] md:h-[360px] object-cover group-hover:scale-110 duration-500"
          />
        </div>
      </Link>
    </div>
  );
}

BlogThumbItemFour.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

BlogThumbItemFour.defaultProps = {
  image: null,
};

export default BlogThumbItemFour;
