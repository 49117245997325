import React from 'react';
import PropTypes from 'prop-types';
import TopicItem from './TopicItem';
import MessageBarTwo from './MessageBarTwo';
import LoadingSpinner from '../atoms/LoadingSpinner';
import topicShape from '../../utils/propHelper/topicShape';

function TopicListTwo({
  topics, onClickTopic, params, isLoading, isError,
}) {
  if (topics === null) return <MessageBarTwo message="No topics available" />;
  if (isLoading) return <LoadingSpinner isLoading={isLoading} />;
  if (isError) {
    return (
      <p className="text-sm md:text-base font-semibold">
        Failed to fetch topics
      </p>
    );
  }

  return (
    <div className="flex flex-col gap-5">
      {topics.length ? (
        topics.map((topic) => (
          <TopicItem
            key={topic.id}
            name={topic.attributes.name}
            onClickTopic={onClickTopic}
            params={params}
          />
        ))
      ) : (
        <MessageBarTwo message="No topics available" />
      )}
    </div>
  );
}

TopicListTwo.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

TopicListTwo.defaultProps = {
  topics: null,
  params: null,
  isLoading: null,
  isError: null,
};

export default TopicListTwo;
