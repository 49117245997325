import React from 'react';
import PropTypes from 'prop-types';
import HeroHeadingSkeleton from '../skeletons/HeroHeadingSkeleton';
import HeroBodySkeleton from '../skeletons/HeroBodySkeleton';

function HeroContent({ heroHeading, heroBody, isLoading }) {
  return (
    <div
      className="
        flex
        flex-col
        gap-5
        text-center
        text-white
        items-center
        px-5
        absolute
        left-0
        right-0
        top-0
        bottom-0
        justify-center
        mt-14
        font-lato"
    >
      {isLoading ? (
        <HeroHeadingSkeleton />
      ) : (
        <h1
          className="
          text-2xl
          leading-tight
          sm:text-3xl
          sm:leading-tight
          md:4xl
          md:leading-tight
          lg:text-5xl
          lg:leading-tight
          xl:text-6xl
          xl:leading-tight
          2xl:text-7xl
          2xl:leading-tight
          tracking-wide
          font-semibold
          uppercase
          max-w-[960px]"
        >
          {heroHeading}
        </h1>
      )}
      {isLoading ? (
        <HeroBodySkeleton />
      ) : (
        <p
          className="
          text-sm
          sm:text-base
          md:text-lg
          lg:text-xl
          xl:text-2xl
          xl:leading-snug
          tracking-tight
          max-w-[780px]"
        >
          {heroBody}
        </p>
      )}
    </div>
  );
}

HeroContent.propTypes = {
  heroHeading: PropTypes.string.isRequired,
  heroBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

HeroContent.defaultProps = {
  isLoading: null,
};

export default HeroContent;
