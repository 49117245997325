import React from 'react';
import PropTypes from 'prop-types';
import TopicItem from './TopicItem';
import MessageBarTwo from './MessageBarTwo';
import TopicsListOneSkeleton from '../skeletons/TopicsListOneSkeleton';
import topicShape from '../../utils/propHelper/topicShape';

function TopicListOne({
  topics, onClickTopic, params, isLoading, isError,
}) {
  if (isLoading) return <TopicsListOneSkeleton />;
  if (isError) return <p className="font-semibold">Failed to fetch topics</p>;

  return (
    <div className="grid grid-cols-2 gap-5 w-full">
      {topics?.length ? (
        topics.map((topic) => (
          <TopicItem
            key={topic.id}
            name={topic.attributes.name}
            onClickTopic={onClickTopic}
            params={params}
          />
        ))
      ) : (
        <MessageBarTwo message="No topics available" />
      )}
    </div>
  );
}

TopicListOne.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

TopicListOne.defaultProps = {
  topics: null,
  params: null,
  isLoading: null,
  isError: null,
};

export default TopicListOne;
