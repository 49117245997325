import React from 'react';
import TextLinkOne from '../atoms/TextLinkOne';

function SocialTextList() {
  return (
    <ul className="flex gap-5 text-sm md:text-base">
      <TextLinkOne text="Instagram" destination="/blogs" />
      <TextLinkOne text="Facebook" destination="/blogs" />
      <TextLinkOne text="Twitter" destination="/blogs" />
    </ul>
  );
}

export default SocialTextList;
