const ActionType = {
  RECEIVE_SERVICES_ABOUT: 'RECEIVE_SERVICES_ABOUT',
  SET_IS_LOADING_SERVICES_ABOUT: 'SET_IS_LOADING_SERVICES_ABOUT',
  SET_IS_ERROR_SERVICES_ABOUT: 'SET_IS_ERROR_SERVICES_ABOUT',
};

function receiveServicesAboutActionCreator(servicesAbout) {
  return {
    type: ActionType.RECEIVE_SERVICES_ABOUT,
    payload: {
      servicesAbout,
    },
  };
}

function setIsLoadingServicesAboutActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_SERVICES_ABOUT,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorServicesAboutActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_SERVICES_ABOUT,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveServicesAboutActionCreator,
  setIsLoadingServicesAboutActionCreator,
  setIsErrorServicesAboutActionCreator,
};
