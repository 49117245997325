import postEmail from '../../data/remote/collection/postEmail';
import {
  setIsErrorEmailActionCreator,
  setIsLoadingEmailActionCreator,
  setIsSuccessEmailActionCreator,
} from './action';

function asyncPostEmail(email, message) {
  return async (dispatch) => {
    dispatch(setIsLoadingEmailActionCreator(true));
    try {
      await postEmail(email, message);
      dispatch(setIsSuccessEmailActionCreator(true));
      dispatch(setIsErrorEmailActionCreator(true));
    } catch (error) {
      dispatch(setIsSuccessEmailActionCreator(false));
      dispatch(setIsErrorEmailActionCreator(true));
    } finally {
      dispatch(setIsLoadingEmailActionCreator(false));
    }
  };
}

export default asyncPostEmail;
