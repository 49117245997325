import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function Logo({ dark = true }) {
  return (
    <Link to="/">
      <div className={`text-center w-fit ${dark ? '' : 'text-white'}`}>
        <h1 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-rosmatika">
          Maton Advisory
        </h1>
        <p className="text-[.575rem] md:text-[.675rem] lg:text-[.775rem] xl:text-sm font-rosario">
          Public Affairs & Policy Engagement
        </p>
      </div>
    </Link>
  );
}

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dark: true,
};

export default Logo;
