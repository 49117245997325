import React from 'react';
import PropTypes from 'prop-types';
import BlogDetailContent from './BlogDetailContent';
import BackButton from './BackButton';
import blogShape from '../../utils/propHelper/blogShape';

function BlogDetail({ blog, isLoading, isError }) {
  return (
    <div className="max-w-[1080px] m-auto flex flex-col gap-5">
      <BackButton />
      <BlogDetailContent blog={blog} isLoading={isLoading} isError={isError} />
    </div>
  );
}

BlogDetail.propTypes = {
  blog: PropTypes.shape(blogShape),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

BlogDetail.defaultProps = {
  blog: null,
  isLoading: null,
  isError: null,
};

export default BlogDetail;
