import initialState from './initialState';
import { ActionType } from './action';

function clientsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_ALL_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
      };
    case ActionType.SET_IS_LOADING_CLIENTS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_CLIENTS:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default clientsReducer;
