import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from '../../assets/images/not-found-image.png';

function NotFound() {
  return (
    <div className="flex flex-col items-center gap-10">
      <img src={NotFoundImage} alt="Not found" />
      <p className="font-semibold text-base md:text-lg lg:text-xl">
        Back to
        {' '}
        <span className="hover:text-[#2F80ED] underline underline-offset-4">
          <Link to="/">Home</Link>
        </span>
      </p>
    </div>
  );
}

export default NotFound;
