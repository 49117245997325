import initialState from './initialState';
import { ActionType } from './action';

function blogsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_INITIAL_BLOGS:
      return {
        ...state,
        blogs: action.payload.blogs,
      };
    case ActionType.SET_MORE_BLOGS:
      return {
        ...state,
        blogs: [...state.blogs, ...action.payload.blogs],
      };
    case ActionType.SET_IS_LOADING_BLOGS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_BLOGS:
      return {
        ...state,
        isError: action.payload.isError,
      };
    case ActionType.SET_PAGE_BLOGS:
      return {
        ...state,
        page: action.payload.page + 1,
      };
    case ActionType.SET_IS_NULL_BLOGS:
      return {
        ...state,
        isNull: action.payload.isNull,
      };
    default:
      return state;
  }
}

export default blogsReducer;
