import React from 'react';
import PropTypes from 'prop-types';
import AboutUsDesc from './AboutUsDesc';
import AboutHeadingSkeleton from '../skeletons/AboutHeadingSkeleton';
import TextHeading from '../atoms/TextHeading';

function AboutUsItem({ aboutHeading, aboutBody, isLoading }) {
  return (
    <div className="flex flex-col gap-5 md:gap-10 font-lato">
      {isLoading ? (
        <AboutHeadingSkeleton />
      ) : (
        <TextHeading text={aboutHeading} />
      )}
      <AboutUsDesc aboutBody={aboutBody} isLoading={isLoading} />
    </div>
  );
}

AboutUsItem.propTypes = {
  aboutHeading: PropTypes.string.isRequired,
  aboutBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

AboutUsItem.defaultProps = {
  isLoading: null,
};

export default AboutUsItem;
