import React from 'react';
import PropTypes from 'prop-types';
import TwitterDark from '../../assets/icons/twitter-dark.svg';
import TwitterLight from '../../assets/icons/twitter-light.svg';
import FacebookDark from '../../assets/icons/facebook-dark.svg';
import FacebookLight from '../../assets/icons/facebook-light.svg';
import LinkedInDark from '../../assets/icons/linkedin-dark.svg';
import LinkedInLight from '../../assets/icons/linkedin-light.svg';
import IconLink from '../atoms/IconLink';

function SocialIconList({ dark = true }) {
  return (
    <ul className="flex gap-3">
      <IconLink
        icon={dark ? TwitterDark : TwitterLight}
        label="Twitter icon"
        destination="/"
      />
      <IconLink
        icon={dark ? FacebookDark : FacebookLight}
        label="Facebook icon"
        destination="/"
      />
      <IconLink
        icon={dark ? LinkedInDark : LinkedInLight}
        label="LinkedIn icon"
        destination="/"
      />
    </ul>
  );
}

SocialIconList.propTypes = {
  dark: PropTypes.bool,
};

SocialIconList.defaultProps = {
  dark: true,
};

export default SocialIconList;
