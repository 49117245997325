import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import useInput from '../../hooks/useInput';
import WindowToolbar from '../../assets/icons/window-toolbar.svg';
import FeatherSend from '../../assets/icons/feather-send.svg';
import SendButton from './SendButton';

function ContactUsInput({ onEmail, isLoading, isSuccess }) {
  const [email, onChangeEmail] = useInput('');
  const [message, onChangeMessage] = useInput('');
  const [reCaptcha, setReCaptcha] = useState('');
  const reCaptchaHandler = (value) => setReCaptcha(value);

  return (
    <form className="flex flex-col gap-10 items-center text-xs md:text-sm lg:text-base">
      <div className="items-center w-full md:w-[620px] lg:w-[720px] xl:w-[820px]">
        <div className="bg-[#403F3F] flex gap-10 px-5 py-3 rounded-t-lg w-full">
          <img src={WindowToolbar} alt="Toolbar" />
          <img src={FeatherSend} alt="Feather send" />
        </div>
        <div
          className="
            flex
            flex-col
            bg-[#2E2E2D]
            text-[#BDBDBD]
            w-full
            py-5
            pb-10
            rounded-b-lg"
        >
          <div className="px-5 overflow-hidden">
            <label htmlFor="emailTo" className="flex gap-5 py-1">
              To:
              <input
                type="email"
                id="emailTo"
                className="bg-transparent text-[#828282] overflow-clip"
                value="contact@maton.co.id"
                disabled
              />
            </label>
            <hr className="p-1 border-[#424242]" />
            <label htmlFor="emailFrom" className="flex gap-5 py-1">
              From:
              <input
                type="email"
                id="emailFrom"
                className="
                  bg-transparent
                  focus:outline-none
                  w-full
                  text-white
                  placeholder:text-[#828282]"
                placeholder="johndoe@example.com"
                value={email}
                onChange={onChangeEmail}
                required
              />
            </label>
            <hr className="p-1 border-[#424242]" />
            <label htmlFor="subject" className="flex flex-row gap-5 py-1">
              Subject:
              <div className="flex items-center w-fit bg-[#1F486A] px-3 rounded-md overflow-clip">
                <input
                  type="text"
                  id="subject"
                  className="bg-transparent text-white"
                  value="I want to collaborate with Maton!"
                  size="28"
                  disabled
                />
                <IoIosArrowDown />
              </div>
            </label>
            <hr className="p-2 border-[#424242]" />
            <label htmlFor="message" className="pb-10">
              <textarea
                id="message"
                rows="10"
                placeholder="Fill your message here!"
                className="
                  bg-transparent
                  w-full
                  focus:outline-none
                  resize-none
                  text-white
                  placeholder:text-[#828282]"
                value={message}
                onChange={onChangeMessage}
                required
              />
            </label>
          </div>
          <hr className="p-2 border-[#424242]" />
        </div>
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GSITE_KEY}
        onChange={reCaptchaHandler}
      />
      <SendButton
        action={onEmail}
        emailValue={email}
        messageValue={message}
        reCaptcha={reCaptcha}
        isLoading={isLoading}
      />
      {isSuccess ? (
        <p className="text-green-700">Message sent successfully</p>
      ) : (
        ''
      )}
    </form>
  );
}

ContactUsInput.propTypes = {
  onEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default ContactUsInput;
