import getServicesHome from '../../data/remote/collection/getServicesHome';
import {
  receiveServicesHomeActionCreator,
  setIsErrorServicesHomeActionCreator,
  setIsLoadingServicesHomeActionCreator,
} from './action';

function asyncReceiveServicesHome() {
  return async (dispatch) => {
    dispatch(setIsLoadingServicesHomeActionCreator(true));
    try {
      const { data } = await getServicesHome();
      dispatch(receiveServicesHomeActionCreator(data));
      dispatch(setIsErrorServicesHomeActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorServicesHomeActionCreator(true));
    } finally {
      dispatch(setIsLoadingServicesHomeActionCreator(false));
    }
  };
}

export default asyncReceiveServicesHome;
