import React from 'react';
import PropTypes from 'prop-types';
import QuoteContentSkeleton from '../skeletons/QuoteContentSkeleton';
import QuoteSpeakerSkeleton from '../skeletons/QuoteSpeakerSkeleton';
import QuoteSpeakerTitleSkeleton from '../skeletons/QuoteSpeakerTitleSkeleton';

function Quote({
  quoteContent, quoteSpeaker, quoteSpeakerTitle, isLoading,
}) {
  return (
    <div className="flex flex-col gap-10 md:text-end font-lato w-full max-w-[728px]">
      <div
        className="
          flex
          flex-col
          gap-5
          text-base
          md:text-lg
          lg:text-xl
          xl:text-2xl
          xl:leading-relaxed"
      >
        {isLoading ? (
          <QuoteContentSkeleton />
        ) : (
          quoteContent.split('\n\n\n').map((paragraph) => (
            <p
              key={paragraph}
              className="text-xs leading-relaxed md:text-xl md:leading-relaxed"
            >
              {paragraph
                .split('\n')
                .reduce((total, line) => [total, <br key={line} />, line])}
            </p>
          ))
        )}
      </div>
      <div className="flex flex-col gap-3 md:items-end font-semibold">
        {isLoading ? (
          <QuoteSpeakerSkeleton />
        ) : (
          <h4 className="text--base md:text-lg lg:text-xl">{quoteSpeaker}</h4>
        )}
        {isLoading ? (
          <QuoteSpeakerTitleSkeleton />
        ) : (
          <p
            className="
            text-sm
            md:text-base
            lg:text-lg
            border-b-4
            border-[#2F80ED]
            w-fit"
          >
            {quoteSpeakerTitle}
          </p>
        )}
      </div>
    </div>
  );
}

Quote.propTypes = {
  quoteContent: PropTypes.string.isRequired,
  quoteSpeaker: PropTypes.string.isRequired,
  quoteSpeakerTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

Quote.defaultProps = {
  isLoading: null,
};

export default Quote;
