import React from 'react';
import GetInTouchItem from '../molecules/GetInTouchItem';
import TextHeadingSection from '../atoms/TextHeadingSection';

function MainHomeFourthSection() {
  return (
    <section className="bg-[#F4F4F4] py-[32px] md:py-[120px]">
      <div className="container m-auto flex flex-col items-center gap-5">
        <TextHeadingSection text="How can we help you?" />
        <GetInTouchItem />
      </div>
    </section>
  );
}

export default MainHomeFourthSection;
