import getAllBlogs from '../../data/remote/collection/getAllBlogs';
import {
  receiveInitialBlogsActionCreator,
  setIsErrorBlogsActionCreator,
  setIsLoadingBlogsActionCreator,
  setIsNullBlogsActionCreator,
  setMoreBlogsActionCreator,
  setPageBlogsActionCreator,
} from './action';

function asyncReceiveInitialBlogs(page) {
  return async (dispatch) => {
    dispatch(setIsLoadingBlogsActionCreator(true));
    try {
      const { data } = await getAllBlogs(page);
      dispatch(receiveInitialBlogsActionCreator(data));
      dispatch(setPageBlogsActionCreator(page));
      dispatch(setIsErrorBlogsActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorBlogsActionCreator(true));
    } finally {
      dispatch(setIsLoadingBlogsActionCreator(false));
    }
  };
}

function asyncSetMoreBlogs(page) {
  return async (dispatch) => {
    dispatch(setIsLoadingBlogsActionCreator(true));
    try {
      const { data } = await getAllBlogs(page);
      if (data.length === 0) dispatch(setIsNullBlogsActionCreator(true));
      else dispatch(setIsNullBlogsActionCreator(false));
      dispatch(setMoreBlogsActionCreator(data));
      dispatch(setPageBlogsActionCreator(page));
      dispatch(setIsErrorBlogsActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorBlogsActionCreator(true));
    } finally {
      dispatch(setIsLoadingBlogsActionCreator(false));
    }
  };
}

export { asyncReceiveInitialBlogs, asyncSetMoreBlogs };
