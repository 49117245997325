import PropTypes from 'prop-types';
import imageDataShape from './imageDataShape';

const aboutServiceAttributeShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape(imageDataShape).isRequired,
};

export default aboutServiceAttributeShape;
