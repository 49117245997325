import React from 'react';
import PropTypes from 'prop-types';

function TextHeadingSection({ text }) {
  return (
    <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold">
      {text}
    </h2>
  );
}

TextHeadingSection.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextHeadingSection;
