import initialState from './initialState';
import { ActionType } from './action';

function homeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionType.RECEIVE_HOME:
      return {
        ...state,
        home: action.payload.home,
      };
    case ActionType.SET_IS_LOADING_HOME:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case ActionType.SET_IS_ERROR_HOME:
      return {
        ...state,
        isError: action.payload.isError,
      };
    default:
      return state;
  }
}

export default homeReducer;
