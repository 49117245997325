import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../organisms/Navbar';
import TextHeroHeading from '../atoms/TextHeroHeading';

function HeroTitle({ title }) {
  return (
    <>
      <div className="absolute w-full bg-white z-10">
        <Navbar />
      </div>
      <div
        className="
          absolute
          left-0
          right-0
          top-0
          bottom-0
          text-white
          flex
          justify-center
          items-center
          mt-16"
      >
        <TextHeroHeading text={title} />
      </div>
    </>
  );
}

HeroTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeroTitle;
