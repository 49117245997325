import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Header from '../molecules/Header';
import MainPrivacyPolicy from '../organisms/MainPrivacyPolicy';
import Footer from '../organisms/Footer';
import asyncReceivePrivacyPolicy from '../../stores/privacyPolicy/thunk';

function PrivacyPolicyPage({
  onSubscribe,
  isLoadingSubscribers,
  isSuccessSubscribers,
  isErrorSubscribers,
}) {
  const dispatch = useDispatch();
  const { privacyPolicy, isLoading, isError } = useSelector(
    (states) => states.privacyPolicy,
  );

  useEffect(() => {
    dispatch(asyncReceivePrivacyPolicy());
  }, [dispatch]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, ease: 'easeOut' }}
      exit={{ opacity: 0 }}
    >
      <Header />
      <MainPrivacyPolicy
        privacyPolicyHeading={
          privacyPolicy !== null
          && privacyPolicy?.length !== 0
          && privacyPolicy?.attributes.heading !== null
            ? privacyPolicy?.attributes.heading
            : 'Privacy Policy'
        }
        privacyPolicyBody={
          privacyPolicy !== null
          && privacyPolicy?.length !== 0
          && privacyPolicy?.attributes.body !== null
            ? privacyPolicy?.attributes.body
            : `Mengapa kami memproses data Anda? Menjaga data pelanggan agar tetap aman
        adalah prioritas utama kami. Oleh karena itu, kami hanya dapat memproses
        sejumlah kecil data pengguna, sebanyak yang benar-benar diperlukan untuk
        menjalankan website. Informasi yang dikumpulkan secara otomatis hanya
        digunakan untuk mengidentifikasi kemungkinan kasus penyalahgunaan dan
        menyusun informasi statistik terkait penggunaan website. Informasi
        statistik ini tidak digabungkan sedemikian rupa hingga dapat
        mengidentifikasi pengguna tertentu dari sistem. Anda dapat mengunjungi
        website tanpa memberi tahu kami identitas Anda atau mengungkapkan
        informasi apa pun, yang dapat digunakan oleh seseorang untuk
        mengidentifikasi Anda sebagai individu tertentu yang dapat dikenali.
        Namun, jika Anda ingin menggunakan beberapa fitur website, atau Anda
        ingin menerima newsletter kami atau memberikan detail lainnya dengan
        mengisi formulir, Anda dapat memberikan data pribadi kepada kami,
        seperti email, nama depan, nama belakang, kota tempat tinggal,
        organisasi, dan nomor telepon Anda. Anda dapat memilih untuk tidak
        memberikan data pribadi Anda kepada kami, tetapi Anda mungkin tidak
        dapat memanfaatkan beberapa fitur website. Contohnya, Anda tidak akan
        dapat menerima Newsletter kami atau menghubungi kami secara langsung
        dari website. Pengguna yang tidak yakin tentang informasi yang wajib
        diberikan dapat menghubungi kami melalui contact@maton.co.id. Hak-hak
        Anda: Jika Anda seorang warga Eropa, Anda memiliki hak-hak berikut
        terkait data pribadi Anda: Hak untuk mendapatkan penjelasan. Hak atas
        akses. Hak untuk memperbaiki. Hak untuk menghapus. Hak untuk membatasi
        pemrosesan. Hak atas portabilitas data. Hak untuk menolak. Hak-hak
        terkait pengambilan keputusan dan pembuatan profil otomatis. Jika Anda
        ingin menggunakan hak ini, silakan hubungi kami melalui informasi kontak
        di bawah ini. Selain itu, jika Anda seorang warga Eropa, perlu diketahui
        bahwa kami akan memproses informasi Anda untuk memenuhi kontrak yang
        mungkin kami miliki dengan Anda (misalnya, jika Anda melakukan pemesanan
        melalui Website), atau untuk memenuhi kepentingan bisnis sah kami
        seperti yang tercantum di atas. Di samping itu, harap diperhatikan bahwa
        informasi Anda mungkin dapat dikirim ke luar Eropa, termasuk Kanada dan
        Amerika Serikat. Link ke website lain: Website kami mungkin berisi
        tautan ke website lain yang tidak dimiliki atau dikendalikan oleh kami.
        Perlu diketahui bahwa kami tidak bertanggung jawab atas praktik privasi
        website lain atau pihak ketiga. Kami menyarankan Anda untuk selalu
        waspada ketika meninggalkan website kami dan membaca pernyataan privasi
        setiap website yang mungkin mengumpulkan informasi pribadi. Keamanan
        informasi: Kami menjaga keamanan informasi yang Anda berikan pada server
        komputer dalam lingkungan yang terkendali, aman, dan terlindungi dari
        akses, penggunaan, atau pengungkapan yang tidak sah. Kami menjaga
        pengamanan administratif, teknis, dan fisik yang wajar untuk
        perlindungan terhadap akses, penggunaan, modifikasi, dan pengungkapan
        tidak sah atas data pribadi dalam kendali dan pengawasannya. Namun, kami
        tidak menjamin tidak akan ada transmisi data melalui Internet atau
        jaringan nirkabel. Pengungkapan hukum: Kami akan mengungkapkan informasi
        apa pun yang kami kumpulkan, gunakan, atau terima jika diwajibkan atau
        diizinkan oleh hukum, misalnya untuk mematuhi panggilan pengadilan atau
        proses hukum serupa, dan jika kami percaya dengan itikad baik bahwa
        pengungkapan diperlukan untuk melindungi hak kami, melindungi
        keselamatan Anda atau keselamatan orang lain, menyelidiki penipuan, atau
        menanggapi permintaan dari pemerintah. Informasi kontak: Jika Anda ingin
        menghubungi kami untuk mempelajari Kebijakan ini lebih lanjut atau
        menanyakan masalah apa pun yang berkaitan dengan hak perorangan dan
        Informasi pribadi Anda, Anda dapat mengirim email ke
        contact@maton.co.id.`
        }
        isLoading={isLoading}
        isError={isError}
      />
      <Footer
        onSubscribe={onSubscribe}
        isLoading={isLoadingSubscribers}
        isSuccess={isSuccessSubscribers}
        isError={isErrorSubscribers}
      />
    </motion.div>
  );
}

PrivacyPolicyPage.propTypes = {
  onSubscribe: PropTypes.func.isRequired,
  isLoadingSubscribers: PropTypes.bool,
  isSuccessSubscribers: PropTypes.bool,
  isErrorSubscribers: PropTypes.bool,
};

PrivacyPolicyPage.defaultProps = {
  isLoadingSubscribers: null,
  isSuccessSubscribers: null,
  isErrorSubscribers: null,
};

export default PrivacyPolicyPage;
