import React from 'react';
import PropTypes from 'prop-types';
import AboutUsItem from '../molecules/AboutUsItem';
import Background from '../../assets/images/background.png';

function MainHomeFirstSection({ aboutHeading, aboutBody, isLoading }) {
  return (
    <section className="relative py-[24px] md:py-32 px-5 text-center bg-[#F4F4F4]">
      <img
        src={Background}
        alt="background images"
        className="absolute w-full h-full left-0 bottom-0 object-cover"
      />
      <AboutUsItem
        aboutHeading={aboutHeading}
        aboutBody={aboutBody}
        isLoading={isLoading}
      />
    </section>
  );
}

MainHomeFirstSection.propTypes = {
  aboutHeading: PropTypes.string.isRequired,
  aboutBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

MainHomeFirstSection.defaultProps = {
  isLoading: null,
};

export default MainHomeFirstSection;
