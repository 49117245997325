import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formattedDate, onClickBlogItem } from '../../utils/common';
import BlogThumbInfo from './BlogThumbInfo';
import NoImageAvailable from '../../assets/images/no-image-available.png';

function BlogThumbItemFive({
  slug, date, title, body, image, params,
}) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-5 hover:shadow-md rounded-lg p-1 duration-150">
        <Link
          to={params ? `/blogs/${slug}?topic=${params}` : `/blogs/${slug}`}
          className="flex flex-col-reverse md:flex-row gap-5 w-full md:justify-between"
          onClick={onClickBlogItem}
        >
          <BlogThumbInfo date={formattedDate(date)} title={title} body={body} />
          <div className="w-full md:w-96">
            <img
              src={
                image
                  ? `${process.env.REACT_APP_BASE_URL}${image}`
                  : NoImageAvailable
              }
              alt="Blog"
              className="rounded-lg w-full h-[250px] object-cover"
            />
          </div>
        </Link>
      </div>
      <hr />
    </div>
  );
}

BlogThumbItemFive.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string,
  params: PropTypes.string,
};

BlogThumbItemFive.defaultProps = {
  image: null,
  params: null,
};

export default BlogThumbItemFive;
