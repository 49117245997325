import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import PropTypes from 'prop-types';

function Button({ label, action }) {
  return (
    <button
      type="button"
      className="flex items-center gap-2 text-[#757575] w-fit text-xs md:text-sm lg:text-base"
      onClick={action}
    >
      <AiOutlineArrowLeft />
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default Button;
