import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { formattedDate, onClickBlogItem } from '../../utils/common';
import NoImageAvailable from '../../assets/images/no-image-available.png';

function BlogThumbItemTwo({
  slug, date, title, image,
}) {
  return (
    <div
      className="
        flex
        items-center
        max-w-[720px]
        hover:text-[#2F80ED]
        text-xl
        hover:text-[1.4em]
        overflow-hidden
        group border"
    >
      <Link
        to={`/blogs/${slug}`}
        className="
          flex
          flex-col
          md:flex-row
          md:items-center
          hover:text-[#2F80ED]
          text-xl
          w-full
          group-hover:text-[1.4em]"
        onClick={onClickBlogItem}
      >
        <div className="overflow-hidden h-full w-full">
          <img
            src={
              image
                ? `${process.env.REACT_APP_BASE_URL}${image}`
                : NoImageAvailable
            }
            alt="Blog"
            className="
              w-full
              h-[250px]
              lg:h-[238px]
              group-hover:scale-110
              duration-500
              object-cover"
          />
        </div>
        <div className="p-5 font-semibold w-full">
          <p
            className="
              text-xs
              group-hover:text-[.85rem]
              md:text-sm
              md:group-hover:text-[.975rem]
              lg:text-base
              lg:group-hover:text-[1.1rem]
              duration-500"
          >
            {formattedDate(date)}
          </p>
          <h2
            className="
              text-xs
              group-hover:text-[1.1rem]
              md:text-sm
              md:group-hover:text-[1.225rem]
              lg:text-base
              lg:group-hover:text-[1.35rem]
              xl:text-lg
              xl:group-hover:text-[1.6rem]
              py-2
              duration-500"
          >
            {title}
          </h2>
          <div className="flex items-center gap-2 text-[#2F80ED] text-lg">
            <p className="text-xs md:text-sm lg:text-base">Read more </p>
            <AiOutlineArrowRight />
          </div>
        </div>
      </Link>
    </div>
  );
}

BlogThumbItemTwo.propTypes = {
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

BlogThumbItemTwo.defaultProps = {
  image: null,
};

export default BlogThumbItemTwo;
