import React from 'react';
import AboutUsServiceItemSkeleton from './AboutUsServiceItemSkeleton';

function AboutUsServicesListSkeleton() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
      <AboutUsServiceItemSkeleton />
    </div>
  );
}

export default AboutUsServicesListSkeleton;
