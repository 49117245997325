import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextLinkTwo({ text, destination }) {
  return (
    <Link
      to={destination}
      className="
        hover:underline
        hover:decoration-4
        hover:underline-offset-8
        hover:decoration-black
        w-full
        text-center"
    >
      {text}
    </Link>
  );
}

TextLinkTwo.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default TextLinkTwo;
