import React from 'react';

function AboutHeadingSkeleton() {
  return (
    <div role="status" className="w-full flex justify-center animate-pulse">
      <div
        className="
          h-2.5
          xl:h-10
          bg-gray-200
          rounded-full
          dark:bg-gray-700
          w-full
          xl:max-w-md
          mb-4"
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default AboutHeadingSkeleton;
