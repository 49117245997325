const ActionType = {
  SET_IS_LOADING_SUBSCRIBE: 'SET_IS_LOADING_SUBSCRIBER',
  SET_IS_SUCCESS_SUBSCRIBE: 'SET_IS_SUCCESS_SUBSCRIBE',
  SET_IS_ERROR_SUBSCRIBER: 'SET_IS_ERROR_SUBSCRIBER',
};

function setIsLoadingSubscribeActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_SUBSCRIBE,
    payload: {
      isLoading,
    },
  };
}

function setIsSuccessSubscribeActionCreator(isSuccess) {
  return {
    type: ActionType.SET_IS_SUCCESS_SUBSCRIBE,
    payload: {
      isSuccess,
    },
  };
}

function setIsErrorSubscribeActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_SUBSCRIBER,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  setIsLoadingSubscribeActionCreator,
  setIsSuccessSubscribeActionCreator,
  setIsErrorSubscribeActionCreator,
};
