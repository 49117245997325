import React from 'react';
import PropTypes from 'prop-types';
import AboutBodySkeleton from '../skeletons/AboutBodySkeleton';
import TextBody from '../atoms/TextBody';

function AboutUsDesc({ aboutBody, isLoading }) {
  if (isLoading) return <AboutBodySkeleton />;
  return <TextBody text={aboutBody} />;
}

AboutUsDesc.propTypes = {
  aboutBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

AboutUsDesc.defaultProps = {
  isLoading: null,
};

export default AboutUsDesc;
