import React from 'react';
import BlogThumbItemFiveSkeleton from './BlogThumbItemFiveSkeleton';

function BlogThumbListTwoSkeleton() {
  return (
    <div className="flex flex-col gap-5">
      <BlogThumbItemFiveSkeleton />
    </div>
  );
}

export default BlogThumbListTwoSkeleton;
