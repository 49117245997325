const ActionType = {
  RECEIVE_HOME: 'RECEIVE_HOME',
  SET_IS_LOADING_HOME: 'SET_IS_LOADING_HOME',
  SET_IS_ERROR_HOME: 'SET_IS_ERROR_HOME',
};

function receiveHomeActionCreator(home) {
  return {
    type: ActionType.RECEIVE_HOME,
    payload: {
      home,
    },
  };
}

function setIsLoadingHomeActionCreator(isLoading) {
  return {
    type: ActionType.SET_IS_LOADING_HOME,
    payload: {
      isLoading,
    },
  };
}

function setIsErrorHomeActionCreator(isError) {
  return {
    type: ActionType.SET_IS_ERROR_HOME,
    payload: {
      isError,
    },
  };
}

export {
  ActionType,
  receiveHomeActionCreator,
  setIsLoadingHomeActionCreator,
  setIsErrorHomeActionCreator,
};
