import React from 'react';
import Navbar from './Navbar';

function HeaderNotFound() {
  return (
    <header className="container m-auto py-5">
      <Navbar />
    </header>
  );
}

export default HeaderNotFound;
