import React from 'react';
import PropTypes from 'prop-types';
import HeroImageSkeleton from '../skeletons/HeroImageSkeleton';
import Video from '../atoms/Video';

function HeroItem({ video, isLoading }) {
  if (isLoading) return <HeroImageSkeleton />;

  return (
    <>
      <Video video={video} />
      <div className="absolute inset-0 bg-black opacity-75" />
    </>
  );
}

HeroItem.propTypes = {
  video: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

HeroItem.defaultProps = {
  isLoading: null,
};

export default HeroItem;
