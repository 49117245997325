import getServicesAbout from '../../data/remote/collection/getServicesAbout';
import {
  receiveServicesAboutActionCreator,
  setIsErrorServicesAboutActionCreator,
  setIsLoadingServicesAboutActionCreator,
} from './action';

function asyncReceiveServicesAbout() {
  return async (dispatch) => {
    dispatch(setIsLoadingServicesAboutActionCreator(true));
    try {
      const { data } = await getServicesAbout();
      dispatch(receiveServicesAboutActionCreator(data));
      dispatch(setIsErrorServicesAboutActionCreator(false));
    } catch (error) {
      dispatch(setIsErrorServicesAboutActionCreator(true));
    } finally {
      dispatch(setIsLoadingServicesAboutActionCreator(false));
    }
  };
}

export default asyncReceiveServicesAbout;
