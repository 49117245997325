import React from 'react';
import PropTypes from 'prop-types';
import MainBlogPageFirstSection from './MainBlogPageFirstSection';
import MainBlogPageSecondSection from './MainBlogPageSecondSection';
import blogShape from '../../utils/propHelper/blogShape';
import topicShape from '../../utils/propHelper/topicShape';

function MainBlogPage({
  headlineBlogs,
  isLoadingHeadlineBlogs,
  isErrorHeadlineBlogs,
  topics,
  onClickTopic,
  params,
  isLoadingTopics,
  isErrorTopics,
  filteredBlogs,
  blogs,
  isLoadingBlogs,
  isErrorBlogs,
  isNullBlogs,
  onClickLoadMore,
}) {
  return (
    <main>
      <MainBlogPageFirstSection
        headlineBlogs={headlineBlogs}
        isLoading={isLoadingHeadlineBlogs}
        isError={isErrorHeadlineBlogs}
      />
      <MainBlogPageSecondSection
        topics={topics}
        onClickTopic={onClickTopic}
        params={params}
        isLoadingTopics={isLoadingTopics}
        isErrorTopics={isErrorTopics}
        filteredBlogs={filteredBlogs}
        blogs={blogs}
        isLoadingBlogs={isLoadingBlogs}
        isErrorBlogs={isErrorBlogs}
        isNullBlogs={isNullBlogs}
        onClickLoadMore={onClickLoadMore}
      />
    </main>
  );
}

MainBlogPage.propTypes = {
  headlineBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoadingHeadlineBlogs: PropTypes.bool,
  isErrorHeadlineBlogs: PropTypes.bool,
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoadingTopics: PropTypes.bool,
  isErrorTopics: PropTypes.bool,
  filteredBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  blogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoadingBlogs: PropTypes.bool,
  isErrorBlogs: PropTypes.bool,
  isNullBlogs: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
};

MainBlogPage.defaultProps = {
  headlineBlogs: null,
  isLoadingHeadlineBlogs: null,
  isErrorHeadlineBlogs: null,
  topics: null,
  isLoadingTopics: null,
  isErrorTopics: null,
  params: null,
  filteredBlogs: null,
  blogs: null,
  isLoadingBlogs: null,
  isErrorBlogs: null,
  isNullBlogs: null,
};

export default MainBlogPage;
