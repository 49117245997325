import React from 'react';
import HomeServiceItemSkeleton from './HomeServiceItemSkeleton';

function HomeServicesListSkeleton() {
  return (
    <div className="flex flex-col md:flex-row gap-5 justify-center">
      <HomeServiceItemSkeleton />
      <HomeServiceItemSkeleton />
      <HomeServiceItemSkeleton />
      <HomeServiceItemSkeleton />
    </div>
  );
}

export default HomeServicesListSkeleton;
