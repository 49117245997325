import React from 'react';
import PropTypes from 'prop-types';

function Image({ image }) {
  return (
    <img
      src={image}
      alt="Hero"
      className="absolute inset-0 w-full h-full object-cover"
    />
  );
}

Image.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Image;
