import React from 'react';
import { Link } from 'react-router-dom';

function GetInTouchItem() {
  return (
    <p className="text-md md:text-lg lg:text-xl xl:text-2xl">
      <strong>
        <Link
          to="/contact-us"
          className="border-b-4 border-[#2F80ED] hover:bg-[#2F80ED] hover:text-white duration-500"
        >
          Get in touch
        </Link>
      </strong>
      {' '}
      with us
    </p>
  );
}
export default GetInTouchItem;
