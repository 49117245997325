import React from 'react';
import Navbar from './Navbar';

function HeaderBlogPage() {
  return (
    <header>
      <Navbar />
    </header>
  );
}

export default HeaderBlogPage;
