import React from 'react';
import PropTypes from 'prop-types';
import BlogThumbListBackgroundTwo from '../../assets/images/blog-thumb-list-background-two.jpeg';
import TopicListTwo from '../molecules/TopicListTwo';
import BlogThumbListTwo from '../molecules/BlogThumbListTwo';
import Sidebar from './Sidebar';
import BlogFooter from '../molecules/BlogFooter';
import topicShape from '../../utils/propHelper/topicShape';
import blogShape from '../../utils/propHelper/blogShape';

function MainDetailBlogSecondSection({
  topics,
  onClickTopic,
  params,
  isLoadingTopics,
  isErrorTopics,
  filteredBlogs,
  blogsList,
  isLoadingBlogs,
  isErrorBlogs,
  isNullBlogs,
  onClickLoadMore,
}) {
  return (
    <section className="relative md:py-16 md:px-10">
      <img
        src={BlogThumbListBackgroundTwo}
        alt="Hero"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black opacity-75" />
      <div
        className="
          container
          m-auto
          flex
          justify-between
          gap-16
          bg-white
          p-5
          md:p-10
          md:rounded-lg
          relative
          md:shadow-2xl
          w-full
          2xl:w-[1440px]"
      >
        <div className="flex flex-col gap-10 w-full">
          <BlogThumbListTwo
            blogs={params ? filteredBlogs : blogsList}
            params={params}
            isLoading={isLoadingBlogs}
            isError={isErrorBlogs}
            isNull={isNullBlogs}
            onClickLoadMore={onClickLoadMore}
          />
        </div>
        <div className="hidden xl:block w-[480px]">
          <Sidebar
            topics={topics}
            onClickTopic={onClickTopic}
            params={params}
            isLoading={isLoadingTopics}
            isError={isErrorTopics}
          />
        </div>
      </div>
      <div className="absolute p-5 flex flex-col gap-5 sm:hidden w-full">
        <h3 className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold">
          See Other Topics
        </h3>
        <TopicListTwo
          topics={topics}
          onClickTopic={onClickTopic}
          params={params}
          isLoading={isLoadingTopics}
          isError={isErrorTopics}
        />
        <hr className="bg-gray-100 border-1" />
        <div className="flex flex-col gap-5">
          <div className="flex justify-center">
            <BlogFooter />
          </div>
        </div>
      </div>
    </section>
  );
}

MainDetailBlogSecondSection.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(topicShape)),
  onClickTopic: PropTypes.func.isRequired,
  params: PropTypes.string,
  isLoadingTopics: PropTypes.bool,
  isErrorTopics: PropTypes.bool,
  filteredBlogs: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  blogsList: PropTypes.arrayOf(PropTypes.shape(blogShape)),
  isLoadingBlogs: PropTypes.bool,
  isErrorBlogs: PropTypes.bool,
  isNullBlogs: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
};

MainDetailBlogSecondSection.defaultProps = {
  topics: null,
  isLoadingTopics: null,
  isErrorTopics: null,
  params: null,
  filteredBlogs: null,
  blogsList: null,
  isLoadingBlogs: null,
  isErrorBlogs: null,
  isNullBlogs: null,
};

export default MainDetailBlogSecondSection;
