import React from 'react';
import PropTypes from 'prop-types';
import BackButton from '../molecules/BackButton';
import PrivacyPolicyItem from '../molecules/PrivacyPolicyItem';

function MainPrivacyPolicy({
  privacyPolicyHeading,
  privacyPolicyBody,
  isLoading,
  isError,
}) {
  return (
    <section className="container m-auto py-16 px-10">
      <div className="max-w-[1080px] m-auto flex flex-col gap-5">
        <BackButton />
        <PrivacyPolicyItem
          privacyPolicyHeading={privacyPolicyHeading}
          privacyPolicyBody={privacyPolicyBody}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
    </section>
  );
}

MainPrivacyPolicy.propTypes = {
  privacyPolicyHeading: PropTypes.string.isRequired,
  privacyPolicyBody: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

MainPrivacyPolicy.defaultProps = {
  isLoading: null,
  isError: null,
};

export default MainPrivacyPolicy;
