import React from 'react';
import PropTypes from 'prop-types';
import MainContactUsSection from './MainContactUsSection';

function MainContactUs({ onEmail, isLoading, isSuccess }) {
  return (
    <main>
      <MainContactUsSection
        onEmail={onEmail}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </main>
  );
}

MainContactUs.propTypes = {
  onEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default MainContactUs;
