import React from 'react';
import { FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';
import useToggle from '../../hooks/useToggle';
import Logo from '../molecules/Logo';
import TopNavigation from '../molecules/TopNavigation';

function Navbar({ dark = true }) {
  const [isOpen, setIsOpen] = useToggle(false);

  return (
    <div
      className="
        container
        m-auto
        md:flex
        md:flex-row
        md:justify-between
        items-center
        px-5
        pt-10
        py-5
        relative
        z-10"
    >
      <div className="flex justify-between">
        <Logo dark={dark} />
        <button
          type="button"
          onClick={setIsOpen}
          className={`${dark ? '' : 'text-white'} md:hidden`}
        >
          <FaBars size={30} />
        </button>
      </div>
      <div className="absolute md:relative left-0 right-0 z-10">
        <TopNavigation isOpen={isOpen} />
      </div>
    </div>
  );
}

Navbar.propTypes = {
  dark: PropTypes.bool,
};

Navbar.defaultProps = {
  dark: true,
};

export default Navbar;
