import React from 'react';
import Logo from '../molecules/Logo';
import BottomNavigation from '../molecules/BottomNavigation';
import SocialIconList from '../molecules/SocialIconList';
import CopyrightItem from '../molecules/CopyrightItem';

function Footer() {
  return (
    <footer className="bg-[#101828] py-14 px-5 border-t md:border-none">
      <div className="container m-auto flex flex-col gap-10">
        <div className="flex flex-col md:flex-row md:justify-between gap-14 items-center">
          <div className="m-auto md:m-0 w-fit">
            <Logo dark={false} />
          </div>
          {/* <SubscribeItem
            onSubscribe={onSubscribe}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
          /> */}
          <div className="flex flex-col gap-3 text-white text-sm md:text-base text-center items-center">
            <p className="font-semibold text-white text-xs md:text-sm lg:text-base xl:text-lg">
              Address
            </p>
            <div className="flex flex-col gap-1 text-center">
              <p>Gedung Graha Mampang, Lantai 3 Suite 305</p>
              <p>
                Jl. M Duren Tiga, Pancoran, Kota Adm. Jakarta Selatan, DKI
                Jakarta
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-3">
            <BottomNavigation />
            <SocialIconList dark={false} />
          </div>
        </div>
        <div className="text-center text-white">
          <CopyrightItem />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {
  isLoading: null,
  isSuccess: null,
  isError: null,
};

export default Footer;
